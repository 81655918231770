//**********************************************************************************************************************************************************/''
//程式代號：CGServerAction.js
//程式名稱：全景伺服器動作函式
//作業說明：在此檔案中，定義的是一些原本須在後端處理的動作，透過ajax的方式也讓這些動作在前端的js就處理完成。
// No.  YYYY/MM/DD  VER     AUTHOR      COMMENTS
//  1.  2022/10/17  1.0       KC         Create
//**********************************************************************************************************************************************************/''
import $ from 'jquery';

var CGServerAction = {    
    //從VA Server取得簽章Token
    GetCrToken: function () {
        var result;
        $.ajax({ 
        type: "POST", 
        async: false, 
        url: "/api/ca2common/CGServerAction/GetCrToken", //"/CA2/CG/Page/CGServerAction.aspx",
        datatype: "text", 
            success: function (data) {
                result = data;
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                result = 'Exception|' + '查詢CGServerAction失敗，未預期的錯誤發生';
                console.log('Error-->[textStatus:' + textStatus + '][errorThrown:' + errorThrown + '][XMLHttpRequest.responseText:' + XMLHttpRequest.responseText + ']');
            }
        });
        //查詢有值，執行command的命令 
        var commands = result.split('|');
        if (commands.length != 1) {
            if (commands[0] == 'Exception') { throw new Error(commands[1]); }
            if (commands[0] == 'PxxtJsonException') { var jsonMSg = JSON.parse(commands[1]); throw jsonMSg; }
        }
        return result;
    }    
}

export default CGServerAction;