//**********************************************************************************************************************************************************/''
//程式代號：CA2HealthCard.js
//程式名稱：健保卡類別
//作業說明：在此類別中，撰寫與健保卡有關的業務邏輯。
// No.  YYYY/MM/DD  VER     AUTHOR      COMMENTS
//  1.  2022/10/17  1.0       KC         Create
//**********************************************************************************************************************************************************/''
import CA2Factory from './CA2Factory.js';
import CA2DataType from './CA2DataType.js';
import CA2ServerAction from './CA2ServerAction.js';
import ICCpkiSingleton from '../ICC/ICCpkiSingleton.js';

//健保卡
var CA2HealthCard = function (UserInputPW) {
    //欄位.憑證卡種類
    this.CardType = function () { return "CA2HealthCard"; }

    //欄位.產生晶片讀卡機
    this.CreateSmartCardReader = function () { m_DefaultReader = CA2Factory.CreateSmartCardReader(); m_DefaultReader.SetDefaultCard(this); return m_DefaultReader; }

    //欄位.產生健保讀卡機
    this.CreateNHIReader = function () { m_DefaultReader = CA2Factory.CreateNHIReader(); m_DefaultReader.SetDefaultCard(this); return m_DefaultReader; }

    //欄位.畫面輸入的密碼(ReadOnly)
    var m_UserInputPW = UserInputPW;
    this.GetPW = function () {
        if (this.GetDefaultReader().ReaderType() == 'SmartCardReader') return CheckPWNull(m_UserInputPW);
        return m_UserInputPW;
    }

    //檢查欄位null值
    function CheckPWNull(m_PW) {
        if (m_PW == null) { throw CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1029); } //健保網路服務註冊密碼未輸入
        return m_PW;
    }

    //欄位.預設讀卡機(Read,Write)
    var m_DefaultReader;
    this.GetDefaultReader = function () { if (m_DefaultReader == null) { m_DefaultReader = CA2Factory.CreateSmartCardReader(); m_DefaultReader.SetDefaultCard(this); } return m_DefaultReader; }
    this.SetDefaultReader = function (CardReader) { m_DefaultReader = CardReader; m_DefaultReader.SetDefaultCard(this); }

    //欄位.身分證號(ReadOnly)
    var m_ID;
    this.ID = function () { if (m_ID == null) { GetCardInfo(this); } return m_ID; }

    //欄位.姓名(ReadOnly)
    var m_Name;
    this.Name = function () { if (m_Name == null) { GetCardInfo(this); } return m_Name; }

    //欄位.生日(ReadOnly)
    var m_Birthday;
    this.Birthday = function () { if (m_Birthday == null) { GetCardInfo(this); } return m_Birthday; }

    //欄位.性別(ReadOnly)
    var m_Sex;
    this.Sex = function () { if (m_Sex == null) { GetCardInfo(this); } return m_Sex; }

    //欄位.發卡日(ReadOnly)
    var m_IssueDate;
    this.IssueDate = function () { if (m_IssueDate == null) { GetCardInfo(this); } return m_IssueDate; }

    //欄位.卡號(ReadOnly)
    var m_CardSN;
    this.CardSN = function () { if (m_CardSN == null) { GetCardInfo(this); } return m_CardSN; }

    //欄位.卡片註銷註記(ReadOnly)
    var m_Cancellation;
    this.Cancellation = function () { if (m_Cancellation == null) { GetCardInfo(this); } return m_Cancellation; }

    //欄位.卡片簽章值
    var m_SignData;
    this.SignData = function () { if (m_SignData == null) { GetCardInfo(this); } return m_SignData; }

    //取得基本資料
    function GetCardInfo(Card) {
        //判斷讀卡機執行相對應的function
        switch (Card.GetDefaultReader().ReaderType()) {
            case "CA2SmartCardReader":
                try {
                    GetCardInfoWithSmartCardReader(Card);
                } catch (e) {
                    if (Object.prototype.toString.call(e) == "[object Object]") { e["ID"] = m_ID; } //記錄失敗ID
                    throw e;
                }
                break;
            default:
                throw CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1001); //無此讀卡機程式邏輯
        }
    }

    //取得基本資料,使用晶片讀卡機
    function GetCardInfoWithSmartCardReader(Card) {
        var BasicData = [];
        BasicData = ICCpkiSingleton.Instance().GetBasicData(Card.GetPW()).split(",");
        m_CardSN = BasicData[0].trimright(); m_Name = BasicData[2].trimright(); m_ID = BasicData[1].trimright(); m_Birthday = BasicData[3].trimright(); m_Sex = BasicData[4].trimright(); m_IssueDate = ""; m_Cancellation = BasicData[5].trimright();

        //取簽章流程
        var clientRandom = ICCpkiSingleton.Instance().GetRandom(); //取亂數值
        var version = ICCpkiSingleton.Instance().GetVersion(); //取版號
        var sRan = version + clientRandom;
        var signature = ICCpkiSingleton.Instance().H_Sign(sRan); //簽章值
        m_SignData = Card.CardSN() + "," + sRan + "," + signature;
    }

    //非同步執行WebSocketAsync動作
    this.WebSocketAsync = function (paras) {
        switch (this.GetDefaultReader().ReaderType()) {
            case "CA2SmartCardReader":
                WebSocketAsyncWithSmartCardReader(paras);
                break;
            default:
                throw CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1001); //無此讀卡機程式邏輯
        }
    }

    //非同步執行WebSocketAsync動作+晶片讀卡機
    function WebSocketAsyncWithSmartCardReader(paras) {
        ICCpkiSingleton.Instance().IccWebSocket().onmessage = function (message) { //S GetBasic
            try {
                var sBas = message.data; if (sBas.length == 4) { throw CA2ServerAction.CodeToMsg(CA2DataType.NHIICC_A, sBas); } sBas = sBas.substring(9, sBas.toString().length); var sCardId = sBas.split(',')[0]; var BasicData = sBas.split(','); m_CardSN = BasicData[0].trimright(); m_Name = BasicData[2].trimright(); m_ID = BasicData[1].trimright(); m_Birthday = BasicData[3].trimright(); m_Sex = BasicData[4].trimright(); m_IssueDate = ""; m_Cancellation = BasicData[5].trimright();
                ICCpkiSingleton.Instance().IccWebSocket().onmessage = function (message) { //S GetVersion
                    try {
                        var sVer = message.data; sVer = sVer.substring(11, sVer.toString().length); //GetVersion:0001"               
                        ICCpkiSingleton.Instance().CheckVersion(sVer); //檢查版本
                        ICCpkiSingleton.Instance().IccWebSocket().onmessage = function (message) { //S GetRandom
                            try {
                                var sRan = message.data; sRan = sRan.substring(10, sRan.toString().length); sRan = sVer + sRan; //GetRandom:6502633484680041"                      
                                ICCpkiSingleton.Instance().IccWebSocket().onmessage = function (message) { //S H_Sign
                                    try {
                                        var sSign = message.data.toString(); sSign = sSign.substring(7, sSign.toString().length); if (sSign.length != 256) { throw CA2ServerAction.CodeToMsg(CA2DataType.NHIICC_A, sSign); }
                                        try {
                                            m_SignData = sCardId + "," + sRan + "," + sSign;
                                        } catch (e) {
                                            if (Object.prototype.toString.call(e) == "[object Object]") { e["ID"] = m_ID; } //記錄失敗ID
                                            throw e;
                                        }
                                        paras.success(); /*呼叫UI層執行成功動作*/
                                    } catch (e) { paras.error(e); /*呼叫UI層執行失敗動作*/ }
                                }; ICCpkiSingleton.Instance().IccWebSocket().send("H_Sign?Random=" + sRan); //E H_Sign
                            } catch (e) { paras.error(e); /*呼叫UI層執行失敗動作*/ }
                        }; ICCpkiSingleton.Instance().IccWebSocket().send("GetRandom"); //E GetRandom
                    } catch (e) { paras.error(e); /*呼叫UI層執行失敗動作*/ }
                }; ICCpkiSingleton.Instance().IccWebSocket().send("GetVersion"); //E GetVersion
            } catch (e) { paras.error(e); /*呼叫UI層執行失敗動作*/ }
        }; ICCpkiSingleton.Instance().IccWebSocket().send("GetBasic"); //E GetBasic
    }
}

export default CA2HealthCard;

