//**********************************************************************************************************************************************************/''
//程式代號：CA2ServerAction.js
//程式名稱：伺服器動作函式
//作業說明：在此檔案中，定義的是一些原本須在後端處理的動作，透過ajax的方式也讓這些動作在前端的js就處理完成。
// No.  YYYY/MM/DD  VER     AUTHOR      COMMENTS
//  1.  2022/10/17  1.0       KC         Create
//**********************************************************************************************************************************************************/''
//import axios from 'axios';
import $ from 'jquery';

var CA2ServerAction = {
    //將錯誤代碼轉為中文JSON格式
    CodeToMsg: function (DataType, Code) {
        var model = { DataType: DataType.toString(), Code: Code.toString() };
        var result;
        $.ajax({
            type: "POST",
            async: false,
            url: "/api/ca2common/CA2ServerAction/CodeToMsgJson",
            dataType: 'json', // 預期從server接收的資料型態
            contentType: 'application/json',//; charset=utf-8', // 要送到server的資料型態
            data: JSON.stringify(model),
            success: function (data) {
                result = data;
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                result = 'Exception|' + '查詢CA2ServerAction失敗，未預期的錯誤發生';
                console.log('Error-->[textStatus:' + textStatus + '][errorThrown:' + errorThrown + '][XMLHttpRequest.responseText:' + XMLHttpRequest.responseText + ']');
            }
        });
        if (typeof result == 'string') {
            //查詢有值，執行command的命令 
            var commands = result.split('|');
            if (commands.length != 1) {
                if (commands[0] == 'Exception') { throw new Error(commands[1]); }
            }
        }
        return result;
    }
}

export default CA2ServerAction;