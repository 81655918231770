var getCGSCUtilATLAdapterObj = function()
{
	var CGShareUrl = "";
	var ServiSignErrorCode = 61001;
	var LibName = "CGSCUtilATLAdapter";								//元件名稱
	var minServiSignVersion = "1.0.16.0418";					//最低ServiSign版本需求
	var minLibVersion = "0.0.0.0";								//最低元件版本需求
	var LibDir = "CG";									//安裝資料夾
	//var JSVersion = "1.0.16.0624";
	
	//var ServiSignFlag_PathProtect    = true;					//路徑保護
	//DataObj.pfxpath_servisignflag = ServiSignFlag_PathProtect;
    //DataObj.pfxpath = pfxpath.replace(/\\/g, "*");

	//DataObj.topuidetect = true;							//設定UI置頂
	function ServiSign_GetErrorCode(){
		return ServiSignErrorCode;
	}
	
	function CGServiSignConnectError(){
		ServiSignErrorCode = 61006;//ServiSign_RTN_CONNECTION_ERROR
	}
	
	function CGServiSignError(){
		ServiSignErrorCode = 61202;//ServiSign_JS_VERSION_FORMAT_ERROR  61202  61202
	}
	function LibError(){
		ServiSignErrorCode = 61201;//ServiSign_JS_LIB_VERSION_FORMAT_ERROR 61201
	}
	function CGServiSignVersionTooLow(){
		ServiSignErrorCode = 61203;//ServiSign_JS_SERVISIGN_VERSION_ERROR 61203
	}
	function LibVersionTooLow(){
		ServiSignErrorCode = 61204;//ServiSign_JS_LIB_VERSION_ERROR   61204
	}
	
	
	var Base64Encode = function (input) {
		return encodeURIComponent(window.btoa(unescape(encodeURIComponent(input))));
	}
	var Base64Decode = function (input) {
		if(input == undefined) return "";
		return decodeURIComponent(escape(window.atob(input)));
	}
	function FixArray(inputArray){
		var array = inputArray.split(";");
		array.splice(array.length - 1, array.length);
		array.toArray = function(){
			return this;
		}
		return array;
	}
	/*function KeepAlive(DataObj){
		var temp = "";
		DataObj.KeepAlive = true;
		DataObj.topuidetect = true;
		do{
			temp = ServiSignObj.Send(DataObj);
		}while(temp == "heartbeat");
		return temp;
	}*/
	function getServiSignObj()
	{
		var Url_Part1 = 'https://localhost:';
		var portList = new Array(56306, 56316, 56336);
		var SessionID ="";
		var WorkURL = "";
		
		var realServiSignVersion = "";
		var realLibVersion = "";
		
		var ServiSignObj = 
		{
			senData : function(url, DataObj){
				var resultObj;		
				var xhr = new XMLHttpRequest();
				xhr.open('POST',url,false);
				xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
				var onLoadHandler = function(){//event){
					try {
						resultObj = JSON.parse(this.responseText);
					}
					catch(err) {
						if(this.responseText != undefined){
							resultObj = new Object();
							resultObj.result = this.responseText.substring(11, this.responseText.length - 2);
						}
						else{
							resultObj = "";
						}
					}
				}
				xhr.onload = onLoadHandler;

				DataObj.comname = LibName;
				DataObj.libdir = LibDir;
				DataObj.minserverversion = minServiSignVersion;
				DataObj.sessionid = SessionID;
								
				var readyDataObj = "Parameter=" + Base64Encode(JSON.stringify(DataObj));
				
				try {
					xhr.send(readyDataObj);
				}
				catch(err) {
					return undefined;
				}
				return resultObj;
			},
			TryPort : function(){
				if(CGShareUrl == "fail") return false;
				else if(CGShareUrl != "") WorkURL = CGShareUrl;
				
				var resultObj;
				var DataObj = new Object();
				DataObj.functionname = "ServiSignEcho";
				DataObj.minlibversion = minLibVersion;
				DataObj.url = document.URL.substring(0, document.URL.indexOf("/", document.URL.indexOf("/") + 2));
				
				if(WorkURL == ""){
					for (var i = 0; i < portList.length; i++) {
						var url = Url_Part1 + portList[i];
						resultObj = this.senData(url, DataObj);
						if(resultObj != undefined){
							WorkURL = url;
							CGShareUrl = url;
							break;
						}
					}
				}
				else{
					resultObj = this.senData(WorkURL, DataObj);
				}
				if(resultObj == undefined){
					CGServiSignConnectError();
					return false;
				}
				ServiSignErrorCode = 0;
				if(WorkURL != ""){
					var VersionToVersion = function(input){
						var result = input;
						result = result.replace(" ","");
						result = result.replace(".",",");
						result = result.replace(".",",");
						result = result.replace(".",",");
						return result.split(",");
					};
					if(resultObj.ServerVersion == undefined){
						ServiSignErrorCode = Base64Decode(resultObj.result);
						return false;
					}
					
					realServiSignVersion = Base64Decode(resultObj.ServerVersion);
					realLibVersion = Base64Decode(resultObj.LibVersion);
					
					var realServerVersionArray = VersionToVersion(realServiSignVersion);
					var realLibVersionArray = VersionToVersion(realLibVersion);
					
					var minServiSignVersionArray = VersionToVersion(minServiSignVersion);
					var minLibVersionArray = VersionToVersion(minLibVersion);
					
					if(realServerVersionArray.length != minServiSignVersionArray.length){
						CGServiSignError();
						return false;
					}
					if(realLibVersionArray.length != minLibVersionArray.length){
						LibError();
						return false;
					}
						
					var VerifyVersion = true;
					for( i = 0 ; i < minServiSignVersionArray.length ; i++){
						if(parseInt(realServerVersionArray[i]) < parseInt(minServiSignVersionArray[i])){
							CGServiSignVersionTooLow();
							VerifyVersion = false;
							break;
						}
						else if(parseInt(realServerVersionArray[i]) > parseInt(minServiSignVersionArray[i])){
							break;
						}
					}
					for( i = 0 ; i < minLibVersionArray.length ; i++){
						if(parseInt(realLibVersionArray[i]) < parseInt(minLibVersionArray[i])){
							LibVersionTooLow();
							VerifyVersion = false;
							break;
						}
						else if(parseInt(realLibVersionArray[i]) > parseInt(minLibVersionArray[i])){
							break;
						}
					}
					if(SessionID == ""){
						SessionID = Base64Decode(resultObj.SessionID);
					}
					return VerifyVersion;
				}
				else{
					CGShareUrl = "fail";
					ServiSignErrorCode = 61001;
					return false;
				}
			},
			Send : function(DataObj){
				DataObj.comname = LibName;
				var resultObj = this.senData(WorkURL, DataObj);
				return Base64Decode(resultObj.result);
			},
			getCGServiSignVersion : function(){
				return realServiSignVersion;
			},
			getLibVersion : function(){
				return realLibVersion;
			},
			ServiSigninit : function()
			{
				var x = new XMLHttpRequest();
				if( window.FormData === undefined || x == undefined){
					alert("This Browser Does NOT support HTML5");
					return false;
				}
				return this.TryPort();
			},
			ServiSignClose : function()
			{
				var DataObj = new Object();
				DataObj.functionname = "ServiSignClose";
				DataObj.minlibversion = minLibVersion;
				return this.Send(DataObj);
			}
		};
		return ServiSignObj;
	}
	var ServiSignObj = getServiSignObj();
	var ServiSignInterface = 
	{
		GetServiSignVersion : function()
		{
			return ServiSignObj.getCGServiSignVersion();
		},
		GetLibVersion : function()
		{
			return ServiSignObj.getLibVersion();
		},
		CGListReaders : function(){
			var DataObj = new Object();
			DataObj.functionname = "CGListReaders";
			return FixArray(ServiSignObj.Send(DataObj));
		},
		ServiSignProperty_propget_debug : function(){
			var DataObj = new Object();
			DataObj.functionname = "ServiSignProperty_propget_debug";
			return parseInt(ServiSignObj.Send(DataObj));
		},
		ServiSignProperty_propput_debug : function(pVal){
			var DataObj = new Object();
			DataObj.functionname = "ServiSignProperty_propput_debug";
			DataObj.pval = pVal;
			return ServiSignObj.Send(DataObj);
		},
		getATR : function(reader){
			var DataObj = new Object();
			DataObj.functionname = "getATR";
			DataObj.reader = reader;
			return FixArray(ServiSignObj.Send(DataObj));
		},
		ServiSignProperty_propget_lastError : function(){
			var DataObj = new Object();
			DataObj.functionname = "ServiSignProperty_propget_lastError";
			return parseInt(ServiSignObj.Send(DataObj));
		},
		transBin2Hex : function(bytesData){
			var DataObj = new Object();
			DataObj.functionname = "transBin2Hex";
			DataObj.bytesdata = bytesData;
			return ServiSignObj.Send(DataObj);
		},
		getCardName : function(reader){
			var DataObj = new Object();
			DataObj.functionname = "getCardName";
			DataObj.reader = reader;
			return ServiSignObj.Send(DataObj);
		},
		CGSCEnumCSPProviders : function(){
			var DataObj = new Object();
			DataObj.functionname = "CGSCEnumCSPProviders";
			return FixArray(ServiSignObj.Send(DataObj));
		},
		fiscGetIssueBank : function(reader){
			var DataObj = new Object();
			DataObj.functionname = "fiscGetIssueBank";
			DataObj.reader = reader;
			return ServiSignObj.Send(DataObj);
		},
		fiscGetAccount : function(reader){
			var DataObj = new Object();
			DataObj.functionname = "fiscGetAccount";
			DataObj.reader = reader;
			return ServiSignObj.Send(DataObj);
		},
		fiscVerifyPIN : function(reader, pin){
			var DataObj = new Object();
			DataObj.functionname = "fiscVerifyPIN";
			DataObj.reader = reader;
			DataObj.pin = pin;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		addAutoScanCSP : function(strCSPName, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "addAutoScanCSP";
			DataObj.strcspname = strCSPName;
			DataObj.iflags = iFlags;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		CGEnableAutoCertReg : function(on, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "CGEnableAutoCertReg";
			DataObj.on = on;
			DataObj.iflags = iFlags;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		ServiSign_GetErrorCode : function()
		{
			return ServiSign_GetErrorCode;
		}
	}
	Object.defineProperties(ServiSignInterface, {
		"debug": {
			get: function(){
				return ServiSignInterface.ServiSignProperty_propget_debug();
			},
			set: function(input){
				ServiSignInterface.ServiSignProperty_propput_debug(input);
			}
		},
		"lastError": {
			get: function(){
				return ServiSignInterface.ServiSignProperty_propget_lastError();
			}
		}
	});
	window.addEventListener("unload", function () {
		ServiSignObj.ServiSignClose();
	});
	if(ServiSignObj.ServiSigninit()){
		return ServiSignInterface;
	}
	return undefined;
}

export default getCGSCUtilATLAdapterObj;
