<template>
  <div>
    <table class="table-default">
      <thead>
        <tr>
          <th colspan="3">
            {{ data_headerMessage }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td>晶片讀卡機</td>
        </tr>
        <tr>
          <td>健保卡</td>
          <td>
            <input
              type="button"
              value="晶片讀卡機.健保卡(輸入健保卡網路服務註冊密碼)"
              @click="Button_click_HealthCard()"
              v-bind:disabled="data_btn_disabled"
            />
            <input
              type="button"
              value="驗簽"
              @click="VerifyHealthCard()"
              v-bind:disabled="data_btn_disabled"
            />
          </td>
        </tr>
        <tr>
          <td>自然人憑證</td>
          <td>
            <input
              type="button"
              value="晶片讀卡機.自然人憑證卡"
              @click="Button_click_NatureHumanCard()"
              v-bind:disabled="data_btn_disabled"
            />
            <input
              type="button"
              value="驗簽"
              @click="VerifyNatureHumanCard()"
              v-bind:disabled="data_btn_disabled"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <hr />
    <input
      id="Buttonjwt"
      type="button"
      value="讀取jwt值"
      @click="Buttonjwt_click()"
      v-bind:disabled="data_btn_disabled"
    />
  </div>
</template>

<script>
//其它共用
import CA2 from "../shared/CA2Common/CA2.js";


export default {
  name: "CA2TestPage", //測試網頁元件名稱
  //網頁變數宣告區
  data() {
    return {
      data_headerMessage: "Internet環境卡片與讀卡機測試網頁", //標題
      data_btn_disabled: false, //所有讀卡按鈕停用控制
      //健保卡
      data_HealthCardGetPW: null,
      data_HealthCardID: null,
      data_HealthCardName: null,
      data_HealthCardBirthday: null,
      data_HealthCardSex: null,
      data_HealthCardIssueDate: null,
      data_HealthCardCardSN: null,
      data_HealthCardCancellation: null,
      data_HealthCardSignData: null,
      //自然人憑證卡
      data_NatureHumanCardID: null,
      data_NatureHumanCardID4: null,
      data_NatureHumanCardName: null,
      data_NatureHumanCardGetX509SignatureCert: null,
      data_NatureHumanCardSignData: null,
      inputId: null,
      inputPIN: null,
    };
  },
  mounted() {
    sessionStorage.clear();
  },
  //網頁方法宣告區
  methods: {
    //健保讀卡機(雲端控軟體).SAM卡[IE瀏覽器+多元瀏覽器]
    Button_click_HealthCard() {
      var self = this;
      self.data_btn_disabled = true; //停用按鈕，此為避免重複執行
      var UserInputPW = prompt("請輸入健保卡網路服務註冊密碼", "999999");
      var objHealthCard = CA2.CA2Factory.CreateHealthCard(UserInputPW); //健保卡
      //讀卡成功
      function CardSuccess() {
        self.data_btn_disabled = false; //啟用按鈕
        //資料呈現
        alert(
          "SAM卡[ID:" +
            objHealthCard.ID() +
            "]" +
            "[Name:" +
            objHealthCard.Name() +
            "]" +
            "[Birthday:" +
            objHealthCard.Birthday() +
            "]" +
            "[Sex:" +
            objHealthCard.Sex() +
            "]" +
            "[IssueDate:" +
            objHealthCard.IssueDate() +
            "]" +
            "[CardSN:" +
            objHealthCard.CardSN() +
            "]" +
            "[Cancellation:" +
            objHealthCard.Cancellation() +
            "]" +
            "[SignData:" +
            objHealthCard.SignData() +
            "]"
        );
        //UI實作button submit動作(含驗簽)
        // $("#<%=Button33.clientid %>").click();
      }
      //讀卡失敗
      function CardError(e) {
        self.data_btn_disabled = false; //啟用按鈕
        CA2.CA2Message.Show(e);
      }
      // 其它瀏覽器使用WebSocket技術
      try {
        objHealthCard.WebSocketAsync({
          //WebSocket中的功能執行成功
          success: function () {
            //簽章資料設定
            self.data_HealthCardID = objHealthCard.ID();
            self.data_HealthCardName = objHealthCard.Name();
            self.data_HealthCardBirthday = objHealthCard.Birthday();
            self.data_HealthCardSex = objHealthCard.Sex();
            self.data_HealthCardIssueDate = objHealthCard.IssueDate();
            self.data_HealthCardCardSN = objHealthCard.CardSN();
            self.data_HealthCardCancellation = objHealthCard.Cancellation();
            self.data_HealthCardSignData = objHealthCard.SignData();
            self.data_HealthCardGetPW = objHealthCard.GetPW();
            CardSuccess();
          },
          error: function (e) {
            CardError(e);
          },
        });
      } catch (e) {
        CardError(e);
      }
    },
    //驗簽-健保讀卡機(雲端控軟體).SAM卡[IE瀏覽器+多元瀏覽器]
    VerifyHealthCard() {
      var self = this;
      self.data_btn_disabled = true; //停用按鈕，此為避免重複執行
      let model = {
        LoginID: this.data_HealthCardID,
        NHIICCSignature: this.data_HealthCardSignData,
        HealthCardPW: this.data_HealthCardGetPW,
        HealthCardCardSN: this.data_HealthCardCardSN,
      };
      this.$http
        .post(
          process.env.VUE_APP_API + "api/ihke000x/ihke000xs01/VerifyHealthCard",
          model
        )
        .then(
          (success) => {
            if (success.data.PxxtJsonException != null) {
              success.data.PxxtJsonException.errors.forEach((element) => {
                CA2.CA2Message.Show(JSON.parse(element.errorMessage));
              });
            } else {
              sessionStorage.setItem(
                "token",
                JSON.parse(JSON.stringify(success.data))
              );
              alert("成功");
            }
          },
          (error) => {
            console.log(error.data);
            alert("系統發生未預期錯誤");
          }
        )
        .finally(() => {
          self.data_btn_disabled = false; //啟用按鈕
        });
    },
    //晶片讀卡機.自然人憑證卡
    Button_click_NatureHumanCard() {
      var self = this;
      self.data_btn_disabled = true; //停用按鈕，此為避免重複執行
      var UserInputPIN = prompt("請輸入卡片PIN碼", "999999");
      var UserInputID = prompt("請輸入身分證號", "Z2******65");
      var objNatureHumanCard = CA2.CA2Factory.CreateNatureHumanCard(UserInputPIN,UserInputID); //自然人憑證卡
      //讀卡成功
      function CardSuccess() {
        self.data_btn_disabled = false; //啟用按鈕
        //資料呈現
        alert(
          "{自然人憑證卡[ID:" +
            objNatureHumanCard.ID() +
            "]" +
            "[ID4:" +
            objNatureHumanCard.ID4() +
            "]" +
            "[Name:" +
            objNatureHumanCard.Name() +
            "]" +
            "[GetX509SignatureCert:" +
            objNatureHumanCard.GetX509SignatureCert() +
            "]" +
            "[SignData:" +
            objNatureHumanCard.SignData() +
            "]}"
        );
      }
      //讀卡失敗
      function CardError(e) {
        self.data_btn_disabled = false; //啟用按鈕
        CA2.CA2Message.Show(e);
      }
      try 
      {
        //簽章資料設定
        self.data_NatureHumanCardID = objNatureHumanCard.ID();
        self.data_NatureHumanCardID4 = objNatureHumanCard.ID4();
        self.data_NatureHumanCardName = objNatureHumanCard.Name();
        self.data_NatureHumanCardGetX509SignatureCert = objNatureHumanCard.GetX509SignatureCert();
        self.data_NatureHumanCardSignData = objNatureHumanCard.SignData();
        CardSuccess();
      } catch (e) {
        CardError(e);
      }
    },
    //驗簽-晶片讀卡機.自然人憑證卡
    VerifyNatureHumanCard() {
      var self = this;
      self.data_btn_disabled = true; //停用按鈕，此為避免重複執行
      let model = {
        LoginID: this.data_NatureHumanCardID,
        CGSignature: this.data_NatureHumanCardSignData,
      };
      this.$http
        .post(
          process.env.VUE_APP_API + "api/ihke000x/ihke000xs01/VerifyNatureHumanCard",
          model
        )
        .then(
          (success) => {
            if (success.data.PxxtJsonException != null) {
              success.data.PxxtJsonException.errors.forEach((element) => {
                CA2.CA2Message.Show(JSON.parse(element.errorMessage));
              });
            } else {
              sessionStorage.setItem(
                "token",
                JSON.parse(JSON.stringify(success.data))
              );
              alert("成功");
            }
          },
          (error) => {
            console.log(error.data);
            alert("系統發生未預期錯誤");
          }
        )
        .finally(() => {
          self.data_btn_disabled = false; //啟用按鈕
        });
    },
    //讀取jwt值
    Buttonjwt_click() {
      var self = this;
      self.data_btn_disabled = true; //停用按鈕，此為避免重複執行
      this.$http
        .get(process.env.VUE_APP_API + "api/ihke000x/ihke000xs01/GetClaims")
        .then(
          (success) => {
            var msg = "";
            success.data.forEach((e) => {
              msg += "[" + e.type + ":" + e.value + "]";
            });
            alert(msg);
          },
          (error) => {
            console.log(error.data);
            alert("系統發生未預期錯誤");
          }
        )
        .finally(() => {
          self.data_btn_disabled = false; //啟用按鈕
        });
    },
  },
};
</script>
