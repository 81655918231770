//**********************************************************************************************************************************************************/''
//程式代號：CGCertTypeOID.js
//程式名稱：全景憑證類別OID共用函式
//作業說明：全景憑證類別OID共用函式
// No.  YYYY/MM/DD  VER     AUTHOR      COMMENTS
//  1.  2022/10/17  1.0       KC         Create
//**********************************************************************************************************************************************************/''

var CGCertTypeOID = {
    CG_CERT_TYPE_OID_1: "2.16.886.1.100.3.1.1", /* 國民 */
    CG_CERT_TYPE_OID_2: "2.16.886.1.100.3.2.1.1", /* 政府機關 */
    CG_CERT_TYPE_OID_3: "2.16.886.1.100.3.2.1.2", /* 政府單位 */
    CG_CERT_TYPE_OID_4: "2.16.886.1.100.3.2.11", /* 學校 */
    CG_CERT_TYPE_OID_5: "2.16.886.1.100.3.2.2.1.1", /* 公司 */
    CG_CERT_TYPE_OID_6: "2.16.886.1.100.3.2.2.2.1", /* 社團法人 */
    CG_CERT_TYPE_OID_7: "2.16.886.1.100.3.2.2.2.2", /* 財團法人 */
    CG_CERT_TYPE_OID_8: "2.16.886.1.100.3.2.3.1", /* 商號 */
    CG_CERT_TYPE_OID_9: "2.16.886.1.100.3.2.3.3.1", /* 分公司 */
    CG_CERT_TYPE_OID_10: "2.16.886.1.100.3.3.1", /* ServerAP */
    CG_CERT_TYPE_OID_11: "2.16.886.1.100.3.1.7", /* 醫事人員, HCA */
    CG_CERT_TYPE_OID_12: "2.16.886.1.100.3.2.21", /* 醫事機構, HCA */
    CG_CERT_TYPE_OID_13: "2.16.886.1.100.3.2.3.4", /* 自由職業事務所, XCA */
    CG_CERT_TYPE_OID_14: "2.16.886.1.100.3.2.49", /* 其他組織或團體, XCA */
    CG_CERT_TYPE_OID_15: "2.16.886.1.100.3.1.9", /* 外來人口 */
    CG_CERT_TYPE_OID_16: "2.16.886.1.100.3.2.2.1.3", /* 有限合夥 */
    CG_CERT_TYPE_OID_17: "2.16.886.1.100.3.2.3.3.2", /* 有限合夥分支機構 */
    CG_CERT_TYPE_OID_18: "2.16.886.1.100.3.2.2.3", /* 行政法人 */
    CG_CERT_TYPE_OID_XX: "2.16.886.1.100.3.3.3" /* 一站式線上申請作業網站授權使用者 */
}

export default CGCertTypeOID;