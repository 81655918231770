//**********************************************************************************************************************************************************/''
//程式代號：CA2PersonalCard.js
//程式名稱：醫事人員憑證卡類別
//作業說明：在此類別中，撰寫與醫事人員憑證卡有關的業務邏輯。
// No.  YYYY/MM/DD  VER     AUTHOR      COMMENTS
//  1.  2022/10/17  1.0       KC         Create
//**********************************************************************************************************************************************************/''
import CA2Factory from './CA2Factory.js';
import CA2DataType from './CA2DataType.js';
import CA2ServerAction from './CA2ServerAction.js';
import CGPkiSingleton from '../CG/CGPkiSingleton.js';
import CGServerAction from '../CG/CGServerAction.js';
import CGBrowserDetect from '../CG/CGBrowserDetect.js';

//醫事人員憑證卡
var CA2PersonalCard = function(PINCode) {
    var m_OsName = CGBrowserDetect.OS; // Windows, Mac, Linux

    //欄位.憑證卡種類
    this.CardType = function () { return "CA2PersonalCard"; }

    //欄位.產生晶片讀卡機
    this.CreateSmartCardReader = function () { m_DefaultReader = CA2Factory.CreateSmartCardReader(); m_DefaultReader.SetDefaultCard(this); return m_DefaultReader; }

    //欄位.畫面輸入的PIN碼(ReadOnly)，空白PIN碼代表卡機驗PIN
    var m_PINCode = PINCode;
    this.GetPINCode = function () {
        if (this.GetDefaultReader().ReaderType() == 'SmartCardReader' && m_PINCode == null) { throw CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1003); } //PIN碼未輸入
        return m_PINCode;
    }

    //欄位.預設讀卡機(Read,Write)
    var m_DefaultReader;
    this.GetDefaultReader = function () { if (m_DefaultReader == null) { m_DefaultReader = CA2Factory.CreateSmartCardReader(); m_DefaultReader.SetDefaultCard(this); } return m_DefaultReader; }
    this.SetDefaultReader = function (CardReader) { m_DefaultReader = CardReader; m_DefaultReader.SetDefaultCard(this); }

    //欄位.身分證號(ReadOnly)
    var m_ID;
    this.ID = function () { if (m_ID == null) { GetCardInfo(this); } return m_ID; }

    //欄位.姓名(ReadOnly)
    var m_Name;
    this.Name = function () { if (m_Name == null) { GetCardInfo(this); } return m_Name; }

    //欄位.生日(ReadOnly)
    var m_Birthday;
    this.Birthday = function () { if (m_Birthday == null) { GetCardInfo(this); } return m_Birthday; }

    //欄位.性別(ReadOnly)
    var m_Sex;
    this.Sex = function () { if (m_Sex == null) { GetCardInfo(this); } return m_Sex; }

    //欄位.類別(ReadOnly)
    var m_Type;
    this.Type = function () { if (m_Type == null) { GetCardInfo(this); } return m_Type; }

    //欄位.卡片簽章值
    var m_SignData;
    this.SignData = function () { if (m_SignData == null) { GetCardInfo(this); } return m_SignData; }

    //取得基本資料
    function GetCardInfo(Card) {
        //判斷讀卡機執行相對應的function
        switch (Card.GetDefaultReader().ReaderType()) {
            case "CA2SmartCardReader":
                if (m_OsName == 'Windows') if (Card.GetDefaultReader().GetReaderArr().length == 0) throw CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1014); //找不到晶片讀卡機
                try {
                    GetCardInfoWithSmartCardReader(Card);
                } catch (e) {
                    if (Object.prototype.toString.call(e) == "[object Object]") { e["ID"] = m_ID; } //記錄失敗ID
                    throw e;
                }
                break;
            default:
                throw CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1001); //無此讀卡機程式邏輯
        }
    }

    //取得基本資料,使用晶片讀卡機
    function GetCardInfoWithSmartCardReader(Card) {
        var api = CGPkiSingleton.Instance("HCA");

        //檢查是否使用正確的卡別。
        var certType = api.CGHCA_AutoGetCardType();
        if (certType != "醫事人員卡") throw CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1026); //請使用醫事人員憑證卡

        var strBasicData = api.CGHCA_GetHPCBasicDataStringByReader().trimright();
        var aBasicData = strBasicData.split("\n");
        m_Name = aBasicData[0];
        m_Sex = aBasicData[2];
        m_Birthday = aBasicData[3];
        m_ID = aBasicData[4];
        m_Type = aBasicData[5];

        //取簽章流程
        //取得一組簽章Token(透過ajax至Server端取Token)
        var CrToken = CGServerAction.GetCrToken();
        //簽章
        var signature = api.CGHCA_SignByReader(CrToken, Card.GetPINCode());
        m_SignData = signature;
    }   
}

export default CA2PersonalCard;