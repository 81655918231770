//**********************************************************************************************************************************************************/''
//程式代號：CGPkiSingleton.js
//程式名稱：全景軟體共用程式獨體類別
//作業說明：在此類別中的物件，可確保在Javascript程式的任何地方，取得同一個物件實體。
// No.  YYYY/MM/DD  VER     AUTHOR      COMMENTS
//  1.  2022/10/17  1.0       KC         Create
//**********************************************************************************************************************************************************/''
import CA2DataType from '../CA2/CA2DataType.js';
import CA2ServerAction from '../CA2/CA2ServerAction.js';
import getCGHCAATLAdapterObj from '../CG/CGHCAATLAdapter.js';
import getCGSCUtilATLAdapterObj from '../CG/CGSCUtilATLAdapter.js'
import getCGGPKICryptATLAdapterObj from '../CG/CGGPKICryptATLAdapter.js';
import CGBrowserDetect from '../CG/CGBrowserDetect.js';
import CA2Common from '../CA2/CA2Common.js';

var CGPkiSingleton = function () {
    var m_instance; //GPKI卡片專用實體
    var m_instanceHCA; //HCA卡片專用實體
    //建立API實體
    function Instance(hca) { return new CGPki(hca); }
    return {
        Instance: function (hca) {
            if (hca == "HCA") { if (!m_instanceHCA) { m_instanceHCA = Instance(hca); } return m_instanceHCA; }
            if (!m_instance) { m_instance = Instance(hca); } return m_instance;
        }
    };
}();

function CGPki(hca) {
    var m_HCA = hca;
    //var m_Site = CA2ServerAction.Site();
    /*
    //ActiveX Object Call
    var FSGPKI_obj = '<object classid="CLSID:3C232DA1-E9AC-4C74-A792-2A686F7315EE" codebase="/CA2/CG/Component/FSGPKICryptATL.cab"' +
    ' width=1 height=1 hspace=1 vspace=1 align=left onerror="installActiveXError(\'FSGPKI\')" id="FSGPKI"></object>';

    var FSHCAATL_obj = '<object classid="CLSID:BB76BF14-7D3D-48CA-8824-42CA1A8FB040" codebase="/CA2/CG/Component/FSHCAATL.cab"' +
    ' width=1 height=1 hspace=2 vspace=1 align=left onerror="installActiveXError(\'FSHCAATL\')" id="FSHCAATL"></object>';

    var FSSCUtil_obj =
    '<object classid="CLSID:2D6F8C95-63E2-441E-8A84-983DE940DA71" codebase="/CA2/CG/Component/FSSCUtilATL.cab"' +
    ' width=1 height=1 hspace=3 vspace=1 align=left onerror="installActiveXError(\'FSSCUtil\')" id="FSSCUtil"></object>';

    */
    var ServiSignDefaultErrorCode = 61001; //全景元件未初始化的預設定義代碼，必須手動設定的原因是元件起不來，也取不到錯誤代碼
    var CGGPKICrypt = "";
    var CGHCAATL = "";
    var CGSCUtil = "";
    var FSGPKICrypt = "";
    var FSHCAATL = "";
    var FSSCUtil = "";

    //---HCA Variable---
    var this_readername = "";
    var this_card_usage;
    var CARD_USAGE_UNKNOWN = -1;
    var CARD_USAGE_HC = 1; // 健保IC卡
    var CARD_USAGE_HPC = 2; // 醫事人員卡
    var CARD_USAGE_HOP = 3; // 醫療機構卡
    // var CGCARDLIB_FLAG_HPC = 0x00000001;
    // var CGCARDLIB_FLAG_HOP = 0x00000004;
    // var CGCARD_RTN_SUCCESS = 0;
    // var CGCARD_RTN_CONNECT_FAIL = 3001;
    // var CGCARD_RTN_SELECT_APPLET_FAIL = 3002;
    // var CGCARD_RTN_ESTABLISH_CONTEXT_FAIL = 3003;
    // var CGCARD_RTN_CARD_ABSENT = 3005;
    // var CGCARD_RTN_TRANSMIT_ERROR = 3006;
    // var CGCARD_RTN_GET_DATA_ERROR = 3007;
    // var CGCARD_RTN_LOGIN_FAIL = 3008;
    // var CGCARD_RTN_READERS_BUFFER_FAIL = 3009;
    // var CGCARD_RTN_GET_READERS_FAIL = 3010;
    // var CGCARD_RTN_NO_READER = 3011;
    // var CGCARD_RTN_MEMALLOC_ERROR = 3012;
    // var CGCARD_RTN_LIST_READERS_ERROR = 3013;
    // var CGCARD_RTN_CHAR2WCHAR_ERROR = 3014;
    // var CGCARD_RTN_WCHAR2CHAR_ERROR = 3015;
    // var CGCARD_RTN_INVALID_PARAM = 3016;
    // var CGCARD_RTN_LIB_EXPIRE = 3017;
    // var CGCARD_RTN_GEN_PKCS7_FAIL = 3018;
    // var CGCARD_RTN_DATA_HASH_ERROR = 3019;
    // var CGCARD_RTN_PIN_LOCK = 3021;
    // var CGCARD_RTN_UNKNOWN_ERROR = 3999;


    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    //JavaApplet Object Call
    var browserName = CGBrowserDetect.browser; // getBrowser();
    var run_component = "";
    var osName2 = CGBrowserDetect.OS; // getOS(); //Windows, Mac, Linux
    var DownloadFileMsg = CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1036);

    if (m_HCA == "HCA" && osName2 != "Windows") { throw "目前醫事類卡片僅支援Windows作業系統請用Windows執行元件"; }

    if (osName2 == "Windows" || osName2 == "Linux" || osName2 == "Mac") {	//Windows, Linux, Mac
        if (browserName == "Explorer") {	//IE使用ServiSign//document.write("<meta http-equiv=\"X-UA-Compatible\" content=\"IE=EmulateIE7;requiresActiveX=true\"/>");
            try {
                //                $('head').append(FSGPKI_obj);                //                $('head').append(FSHCAATL_obj);                //                $('head').append(FSSCUtil_obj);
                if (FSGPKICrypt == "") { FSGPKICrypt = new window.ActiveXObject("FSGPKICryptATL.GPKICryptATL"); }
                if (FSHCAATL == "") { FSHCAATL = new window.ActiveXObject("FSHCAATL.FSHCA"); }
                if (FSSCUtil == "") { FSSCUtil = new window.ActiveXObject("FSSCUtilATL.SCUtils"); }
            } catch (e) {
                let msg = CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1035);
                var answer = confirm("錯誤訊息:" + e + "；\n\n" + msg.Cn + "\n[" + msg.Ty + msg.Co + "]"); //請檢查HC憑證元件是否已正確安裝，且環境皆已正確設定\n\n若您尚未安裝元件，請下載安裝元件後重啟瀏覽器。\n\n是否要下載元件安裝程式？
                run_component = "";
                if (answer == true) {
                    DownloadActiveXFile();
                }
                throw ""; //中斷處理程序，但不顯示訊息。
            }

            //如果不符合可用版號要求，強制重新安裝
            let msg = CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1045);
            var NeedUpdateInstaller = function () { var v; try { v = FSGPKICrypt.GetVersion(0); } catch (e) { v = "0"; } var vers = msg.En.split('|'); for (var i = 0; i < vers.length; i++) { if (v == vers[i]) return false; } return true; }
            if (NeedUpdateInstaller()) {
                alert(msg.Cn + "\n[" + msg.Ty + msg.Co + "]");
                DownloadActiveXFile(); throw ""; /*中斷處理程序，但不顯示訊息*/  /*Internet強制更新，VPN僅跳提示，不作自動引導更新。但還是可以流程往後走登入。*/
            }
            run_component = "ActiveX";

        } else if (browserName == "Firefox" || browserName == "Chrome" || browserName == "Edge" || browserName == "Opera" || browserName == "Safari") {   //其他瀏覽器使用ServiSign            
            if (getCookie("hasServiSign") == "") {   //先判斷此電腦是否有安裝ServiSign   
                CGGPKICrypt = getCGGPKICryptATLAdapterObj();
                if (CGGPKICrypt != undefined) {
                    run_component = "ServiSign_" + osName2;
                    //var d = new Date(); //d.setTime(d.getTime() + (21 * 24 * 60 * 60 * 1000)); //Cookie可保存21天 //var expires = "expires=" + d.toUTCString();
                    document.cookie = "hasServiSign=0";
                }
                else {//未安裝ServiSign
                    document.cookie = "hasServiSign=-1";
                }
            } else if (getCookie("hasServiSign") == "0") {	//判斷有安裝ServiSign則Load ServiSign
                CGGPKICrypt = getCGGPKICryptATLAdapterObj();
            } else if (getCookie("hasServiSign") == "-1") {	//判斷沒有安裝ServiSign，給他undefined
                CGGPKICrypt = undefined;
            }

            if (CGGPKICrypt != undefined) {
                run_component = "ServiSign_" + osName2;
                if (osName2 == "Windows") {
                    //如果不符合可用版號要求，強制重新安裝
                    let msg = CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1044);
                    let NeedUpdateInstaller = function () { var v = CGGPKICrypt.GetServiSignVersion(); var vers = msg.En.split('|'); for (var i = 0; i < vers.length; i++) { if (v == vers[i]) return false; } return true; }
                    if (NeedUpdateInstaller()) {
                        alert(msg.Cn + "\n[" + msg.Ty + msg.Co + "]"); DownloadServiSignFile(); throw ""; /*中斷處理程序，但不顯示訊息*/
                    }
                } else if (osName2 == "Mac") {
                    console.log("Mac");
                } else if (osName2 == "Linux") {
                    console.log("Linux");
                }
            } else {  //ServiSign載入失敗//document.write("<meta http-equiv=\"X-UA-Compatible\" content=\"IE=EmulateIE7;requiresActiveX=true\"/>");               
                //alert(run_component);//alert("ServiSign元件載入失敗 (錯誤代碼:" + ServiSign_GetErrorCode() + ")，詳見元件安裝說明!");
                let answer = confirm("錯誤訊息:" + GetServiSignErrorMessage(ServiSignDefaultErrorCode) + "；\n\n" + DownloadFileMsg.Cn + "\n[" + DownloadFileMsg.Ty + DownloadFileMsg.Co + "]"); //請檢查ServiSign元件是否已正確安裝且啟動\n\n若您尚未安裝元件，請下載安裝元件後重啟瀏覽器。\n\n是否要下載元件安裝程式？
                run_component = "";
                if (answer == true) {
                    DownloadServiSignFile();
                }
                throw ""; //中斷處理程序，但不顯示訊息。
            }


            if (run_component == "ServiSign_Windows") {	//把剩下的ServiSign元件載入
                CGHCAATL = getCGHCAATLAdapterObj();
                if (CGHCAATL != undefined) {
                    //alert("CGHCAATL 載入成功");
                } else {
                    throw "CGHCAATL載入失敗，錯誤代碼:" + ServiSignDefaultErrorCode;
                }
                CGSCUtil = getCGSCUtilATLAdapterObj();
                if (CGSCUtil != undefined) {
                    //alert("CGSCUtil 載入成功");
                } else {
                    throw "CGSCUtil載入失敗，錯誤代碼:" + ServiSignDefaultErrorCode;
                }
            } else {    //Linux與Mac沒有這兩顆元件，不需要Load
            }
        } else {    //不認識的瀏覽器
            run_component = "Unknown";
            throw "不支援的瀏覽器: " + browserName + "；請改用Chrome, FireFox, Safari, IE, Edge, Opera等瀏覽器執行" + "\nOS:" + osName2 + ", Browser: " + browserName;
        }
    } else {    //不認識的作業系統不支援
        run_component = "Unknown";
        throw "請使用Windows, Linux, Mac作業系統，並執行ServiSign簽章元件";
    }

    function DownloadActiveXFile() {
        var FileName = "CMS_Internet_HC_Setup.zip";
        var url = CA2Common.DownloadFileUrl() + "/iwse0000/IWSE0030S03.aspx?AttName=" + escape("4|" + FileName); var features = "directories=no,location=no,scrollbars=no,menubar=no,toolbar=no,resizable=yes,height=10,width=10,top=500,left=500"; var hWnd = window.open(url, "_blank", features, "false"); if (!hWnd.opener) hWnd.opener = document.window; hWnd.focus();
    }
    function DownloadServiSignFile() {
        var FileName;
        try {
            var EName = JSON.parse(DownloadFileMsg.En);
            switch (osName2) {
                case "Windows":
                    FileName = EName.Windows.FileName;
                    break;
                case "Mac":
                    FileName = EName.Mac.FileName;
                    break;
                case "Linux":
                    FileName = EName.Linux.FileName;
                    break;
                default:
                    FileName = "CMS_CGServiSignAdapterSetup.zip";
            }
        } catch (e) {
            FileName = "CMS_CGServiSignAdapterSetup.zip";
        }
        var url = CA2Common.DownloadFileUrl() + "/iwse0000/IWSE0030S03.aspx?AttName=" + escape("4|" + FileName); var features = "directories=no,location=no,scrollbars=no,menubar=no,toolbar=no,resizable=yes,height=10,width=10,top=500,left=500"; var hWnd = window.open(url, "_blank", features, "false"); if (!hWnd.opener) hWnd.opener = document.window; hWnd.focus();
    }

    this.CG_DetectCardType = function (PinCode) {	//組合CGGPKI、CGHCA判斷卡片種類
        var PlainData = "Test123123";
        if (PinCode.length == 0 && osName2 != "Windows") { throw "請輸入密碼"; }
        if (run_component == "Unknown") { throw "請使用Windows, Linux, Mac作業系統，並執行ServiSign簽章元件"; }

        // try {
            //var rtn = -1;
            try {
                //rtn = CGGPKI_PKCS7_Sign(PlainData, PinCode);
                CGGPKI_PKCS7_Sign(PlainData, PinCode);
                return "自然人憑證或工商憑證";
            } catch (ex) {
                try {
                    return CGHCA_AutoGetCardType();
                } catch (ex) {
                    throw "無法辨識的卡片";
                }
            }
        // } catch (err) {
        //     throw err;
        // }
    }
    this.CGGPKI_PKCS7_Sign = function(PlainData, PinCode){
        return CGGPKI_PKCS7_Sign(PlainData, PinCode);
    }
    function CGGPKI_PKCS7_Sign(PlainData, PinCode) {	//使用自然人憑證簽章
        //公用參數區-----
        var SignData;
        var rtn;
        // var CG_FLAG_DETACHMSG = 0x00004000; //簽章值包含簽章原文
        var CG_ALGOR_SHA256 = 0x00000004; //採用SHA256演算法簽章
        // var CG_ALGOR_SHA1 = 0x02;
        var CG_FLAG_DOHASH = 0x00010000;
        if (PlainData.length == 0) {
            throw "請輸入原文";
        }
        if (PinCode.length == 0 && osName2 != "Windows") {
            throw "請輸入密碼";
        }
        //---------------
        if (run_component == "Unknown") { throw "請使用Windows, Linux, Mac作業系統，並執行ServiSign簽章元件"; }

        // try {
            if (run_component == "ActiveX") {   //ActiveX元件需要先getElementByID
                // FSGPKICrypt = document.getElementById("FSGPKI");
                SignData = FSGPKICrypt.FSGPKI_SignData(PinCode, PlainData, CG_FLAG_DOHASH | CG_ALGOR_SHA256); //用GPKI憑證SHA256簽章(自然人憑證、工商憑證)
                rtn = FSGPKICrypt.get_lastError(); //取得錯誤代碼
                if (rtn == 0) {	//簽章成功，錯誤代碼:0
                    return SignData;
                } else {	//簽章失敗，顯示錯誤代碼與錯誤訊息
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, rtn);
                }
            } else if (run_component == "ServiSign_" + osName2) {
                SignData = CGGPKICrypt.GPKI_SignData(PinCode, PlainData, "UTF-8", 0, CG_FLAG_DOHASH | CG_ALGOR_SHA256); //用GPKI憑證SHA256簽章(自然人憑證、工商憑證)
                rtn = CGGPKICrypt.get_lastError(); //取得錯誤代碼
                if (rtn == 0) {	//簽章成功，錯誤代碼:0
                    return SignData;
                } else {	//簽章失敗，顯示錯誤代碼與錯誤訊息
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, rtn);
                }
            }

        // } catch (err) {
        //     throw err;
        // }
    }

    this.CGGPKI_EnumCerts = function (flag) {	//列舉憑證 flag=="sign", "data", "" 等三種
        //公用參數區-----
        var flags = "";
        var x509certs;
        var certs;
        var CG_KU_DIGITAL_SIGNATURE = 0x0080; //自然人-簽章憑證
        var CG_KU_DATA_ENCIPHERMENT = 0x0010; //自然人-加解密憑證
        if (flag == "sign") {
            flags = CG_KU_DIGITAL_SIGNATURE;
        } else if (flag == "data") {
            flags = CG_KU_DATA_ENCIPHERMENT;
        } else {//沒給參數，以簽章憑證為主
            flags = CG_KU_DIGITAL_SIGNATURE;
        }
        //---------------
        if (run_component == "Unknown") { throw "請使用Windows, Linux, Mac作業系統，並執行ServiSign簽章元件"; }
        // try {
            if (run_component == "ActiveX") {   //ActiveX元件需要先getElementByID
                // FSGPKICrypt = document.getElementById("FSGPKI");
                FSGPKICrypt.FSGPKI_SetReaderSlot(0, 0);
                certs = FSGPKICrypt.FSGPKI_EnumCerts(flags);
                if (certs == null) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, FSGPKICrypt.get_lastError()); // throw "找不到卡片中的任何憑證： " + FSGPKICrypt.get_lastError();                  
                }
                x509certs = certs.toArray();
                if (x509certs.length == 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, FSGPKICrypt.get_lastError()); // throw "找不到卡片中的任何憑證： " + FSGPKICrypt.get_lastError();
                }
                //alert("卡片中共有 " + x509certs.length + " 張 X.509 憑證，僅回傳第一張憑證");
                return x509certs[0];
            } else if (run_component == "ServiSign_" + osName2) {
                CGGPKICrypt.GPKI_SetReaderSlot(0, 0);
                certs = CGGPKICrypt.GPKI_EnumCerts(flags);
                if (certs == null) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, CGGPKICrypt.get_lastError()); // throw "找不到卡片中的任何憑證： " + CGGPKICrypt.get_lastError();
                }
                x509certs = certs.toArray();
                if (x509certs.length == 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, CGGPKICrypt.get_lastError()); //throw "找不到卡片中的任何憑證： " + CGGPKICrypt.get_lastError();
                }
                //alert("卡片中共有 " + x509certs.length + " 張 X.509 憑證，僅回傳第一張憑證");
                /*var strX509Cert = "";
                for (i = 0; i < x509certs.length; i++) {
                strX509Cert = x509certs[i];
                let msg = "[第" + i + "張] 憑證\n" + 
                "主旨:" + CGGPKICrypt.CAPICertGetSubject(strX509Cert , 0) + "\n" +
                "序號:"+ CGGPKICrypt.CAPICertGetSerialNumber(strX509Cert , 0 ) + "\n" +
                "有效時間:"+ CGGPKICrypt.CAPICertGetNotBefore(strX509Cert , 0 ) + "\n" +
                "有效時間:"+ CGGPKICrypt.CAPICertGetNotAfter(strX509Cert , 0 ) + "\n" 
			
                form.strUserCert.value = strX509Cert;
                alert(msg);
                }*/
                return x509certs[0];
            }
        // } catch (err) {
        //     throw err;
        // }
    }

    this.CGGPKI_CAPICertGetSubject = function (X509Cert) {	//輸入X509Cert Base64回傳憑證主旨
        //公用參數區-----
        var Subjectx;
        var rtn;
        //---------------
        if (run_component == "Unknown") { throw "請使用Windows, Linux, Mac作業系統，並執行ServiSign簽章元件"; }
        if (X509Cert == "") {
            throw "請輸入X509-Base64格式憑證";
        }
        // try {
            if (run_component == "ActiveX") {   //ActiveX元件需要先getElementByID
                // FSGPKICrypt = document.getElementById("FSGPKI");
                Subjectx = FSGPKICrypt.FSCAPICertGetSubject(X509Cert, 0);
                rtn = FSGPKICrypt.get_lastError();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, rtn);
                }
                return Subjectx;
            } else if (run_component == "ServiSign_" + osName2) {
                Subjectx = CGGPKICrypt.CAPICertGetSubject(X509Cert, 0);
                rtn = CGGPKICrypt.get_lastError();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, rtn);
                }
                return Subjectx;
            }
        // } catch (err) {
        //     throw err;
        // }
    }

    this.CGGPKI_CAPICertGetSubjectCN = function (X509Cert) {	//輸入X509Cert Base64回傳憑證CN
        //公用參數區-----
        var Subjectx;
        var rtn;
        //---------------
        if (run_component == "Unknown") { throw "請使用Windows, Linux, Mac作業系統，並執行ServiSign簽章元件"; }
        if (X509Cert == "") {
            throw "請輸入X509-Base64格式憑證";
        }
        var CG_FLAG_SUBJECT_COMMON_NAME = 0x00010000;
        // try {
            if (run_component == "ActiveX") {   //ActiveX元件需要先getElementByID
                // FSGPKICrypt = document.getElementById("FSGPKI");
                Subjectx = FSGPKICrypt.FSCAPICertGetSubject(X509Cert, CG_FLAG_SUBJECT_COMMON_NAME);
                rtn = FSGPKICrypt.get_lastError();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, rtn);
                }
                return Subjectx;
            } else if (run_component == "ServiSign_" + osName2) {
                Subjectx = CGGPKICrypt.CAPICertGetSubject(X509Cert, CG_FLAG_SUBJECT_COMMON_NAME);
                rtn = CGGPKICrypt.get_lastError();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, rtn);
                }
                return Subjectx;
            }
        // } catch (err) {
        //     throw err;
        // }
    }

    this.CGGPKI_GetSerialNumber = function (X509Cert) {	//輸入X509Cert Base64回傳憑證序號
        //公用參數區-----
        var SerialNumber;
        var rtn;
        //---------------
        if (run_component == "Unknown") { throw "請使用Windows, Linux, Mac作業系統，並執行ServiSign簽章元件"; }
        if (X509Cert == "") {
            throw "請輸入X509-Base64格式憑證";
        }
        // try {
            if (run_component == "ActiveX") {   //ActiveX元件需要先getElementByID
                // FSGPKICrypt = document.getElementById("FSGPKI");
                SerialNumber = FSGPKICrypt.FSXCAPICertGetSerialNumber(X509Cert, 0);
                rtn = FSGPKICrypt.get_lastError();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, rtn);
                }
                return SerialNumber;
            } else if (run_component == "ServiSign_" + osName2) {
                SerialNumber = CGGPKICrypt.CAPICertGetSerialNumber(X509Cert, 0);
                rtn = CGGPKICrypt.get_lastError();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, rtn);
                }
                return SerialNumber;
            }
        // } catch (err) {
        //     throw err;
        // }
    }

    this.CGGPKI_GetTailOfCitizenID = function (X509Cert) {	//輸入X509Cert Base64回傳身分證字號後四碼、統一編號後四碼
        //公用參數區-----
        var SerialNumber;
        var rtn;
        //---------------
        if (run_component == "Unknown") { throw "請使用Windows, Linux, Mac作業系統，並執行ServiSign簽章元件"; }
        if (X509Cert == "") {
            throw "請輸入X509-Base64格式憑證";
        }
        // try {
            if (run_component == "ActiveX") {   //ActiveX元件需要先getElementByID
                // FSGPKICrypt = document.getElementById("FSGPKI");
                SerialNumber = FSGPKICrypt.GPKI_GetTailOfCitizenID(X509Cert, 0);
                rtn = FSGPKICrypt.get_lastError();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, rtn);
                }
                return SerialNumber;
            } else if (run_component == "ServiSign_" + osName2) {
                SerialNumber = CGGPKICrypt.GPKI_GetTailOfCitizenID(X509Cert, 0);
                rtn = CGGPKICrypt.get_lastError();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, rtn);
                }
                return SerialNumber;
            }
        // } catch (err) {
        //     throw err;
        // }
    }

    this.CGGPKI_GetCertType = function (X509Cert) {	//輸入X509Cert Base64回傳憑證類別
        //公用參數區-----
        var CertType;
        var rtn;
        //---------------
        if (run_component == "Unknown") { throw "請使用Windows, Linux, Mac作業系統，並執行ServiSign簽章元件"; }
        if (X509Cert == "") {
            throw "請輸入X509-Base64格式憑證";
        }
        // try {
            if (run_component == "ActiveX") {   //ActiveX元件需要先getElementByID
                // FSGPKICrypt = document.getElementById("FSGPKI");
                CertType = FSGPKICrypt.GPKI_GetCertType(X509Cert, 0);
                rtn = FSGPKICrypt.get_lastError();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, rtn);
                }
                return CertType;
            } else if (run_component == "ServiSign_" + osName2) {
                CertType = CGGPKICrypt.GPKI_GetCertType(X509Cert, 0);
                rtn = CGGPKICrypt.get_lastError();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, rtn);
                }
                return CertType;
            }
        // } catch (err) {
        //     throw err;
        // }
    }

    this.CGGPKI_GetCertTypeOID = function (X509Cert) {	//輸入X509Cert Base64回傳憑證類別
        //公用參數區-----
        var CertTypeOID;
        var rtn;
        //---------------
        if (run_component == "Unknown") { throw "請使用Windows, Linux, Mac作業系統，並執行ServiSign簽章元件"; }
        if (X509Cert == "") {
            throw "請輸入X509-Base64格式憑證";
        }
        // try {
            if (run_component == "ActiveX") {   //ActiveX元件需要先getElementByID
                // FSGPKICrypt = document.getElementById("FSGPKI");
                CertTypeOID = FSGPKICrypt.GPKI_GetCertTypeOID(X509Cert, 0);
                rtn = FSGPKICrypt.get_lastError();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, rtn);
                }
                return CertTypeOID;
            } else if (run_component == "ServiSign_" + osName2) {
                CertTypeOID = CGGPKICrypt.GPKI_GetCertTypeOID(X509Cert, 0);
                rtn = CGGPKICrypt.get_lastError();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, rtn);
                }
                return CertTypeOID;
            }
        // } catch (err) {
        //     throw err;
        // }
    }

    this.CGGPKI_GetEntityOID = function (X509Cert) {	//輸入X509Cert Base64回傳憑證類別
        //公用參數區-----
        var EntityOID;
        var rtn;
        //---------------
        if (run_component == "Unknown") { throw "請使用Windows, Linux, Mac作業系統，並執行ServiSign簽章元件"; }
        if (X509Cert == "") {
            throw "請輸入X509-Base64格式憑證";
        }
        // try {
            if (run_component == "ActiveX") {   //ActiveX元件需要先getElementByID
                // FSGPKICrypt = document.getElementById("FSGPKI");
                EntityOID = FSGPKICrypt.GPKI_GetEntityOID(X509Cert, 0);
                rtn = FSGPKICrypt.get_lastError();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, rtn);
                }
                return EntityOID;
            } else if (run_component == "ServiSign_" + osName2) {
                EntityOID = CGGPKICrypt.GPKI_GetEntityOID(X509Cert, 0);
                rtn = CGGPKICrypt.get_lastError();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, rtn);
                }
                return EntityOID;
            }
        // } catch (err) {
        //     throw err;
        // }
    }

    this.CGGPKI_GetOrganizationID = function (X509Cert) {	//輸入X509Cert Base64回傳憑證類別
        //公用參數區-----
        var OrganizationID;
        var rtn;
        //---------------
        if (run_component == "Unknown") { throw "請使用Windows, Linux, Mac作業系統，並執行ServiSign簽章元件"; }
        if (X509Cert == "") {
            throw "請輸入X509-Base64格式憑證";
        }
        // try {
            if (run_component == "ActiveX") {   //ActiveX元件需要先getElementByID
                // FSGPKICrypt = document.getElementById("FSGPKI");
                OrganizationID = FSGPKICrypt.GPKI_GetOrganizationID(X509Cert, 0);
                rtn = FSGPKICrypt.get_lastError();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, rtn);
                }
                return OrganizationID;
            } else if (run_component == "ServiSign_" + osName2) {
                OrganizationID = CGGPKICrypt.GPKI_GetOrganizationID(X509Cert, 0);
                rtn = CGGPKICrypt.get_lastError();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGGPKICryptATL_A, rtn);
                }
                return OrganizationID;
            }
        // } catch (err) {
        //     throw err;
        // }
    }

    this.CGHCA_AutoGetCardType = function(){
        return CGHCA_AutoGetCardType();
    }
    function CGHCA_AutoGetCardType() {
        //公用參數區-----
        var reader;
        var readers;
        var rtn;
        var type;
        var i;
        var my_Reader;

        //---------------
        if (run_component == "Unknown" || run_component == "ServiSign_Linux" || run_component == "ServiSign_Mac") { throw "目前HCA元件僅支援Windows請用Windows執行元件"; }
        // try {
            if (run_component == "ActiveX") {   //ActiveX元件需要先getElementByID
                // FSHCAATL = document.getElementById("FSHCAATL");
                my_Reader = FSHCAATL.FSHCA_GetReaderNames(0);
                if (my_Reader == null) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGHCAATL_A, FSHCAATL.FSHCA_GetErrorCode()); // throw "找不到可使用的讀卡機! " + GetErrorMessage(FSHCAATL.FSHCA_GetErrorCode());
                } else {
                    readers = my_Reader.toArray();
                    this_card_usage = -1; //使用中卡片預設值
                    for (i = 0; i < readers.length; i++) {
                        reader = readers[i];
                        this_readername = reader;
                        FSHCAATL.FSHCA_GetHCBasicDataByReader(this_readername, 0);
                        if (FSHCAATL.FSHCA_GetErrorCode() == 0) {
                            this_card_usage = CARD_USAGE_HC;
                            return "健保IC卡";
                        }
                        type = FSHCAATL.FSHCA_GetCardType(this_readername);
                        if (FSHCAATL.FSHCA_GetErrorCode() == 0) {
                            if (type == 0) {
                                this_card_usage = CARD_USAGE_HOP;
                                return "醫事機構卡";
                            } else if (type == 1) {
                                this_card_usage = CARD_USAGE_HPC;
                                return "醫事人員卡";
                            }
                        }
                    } // end for
                    //document.queryform.READER.value = this_readername;
                    if (this_card_usage == CARD_USAGE_UNKNOWN) {
                        rtn = FSHCAATL.FSHCA_GetErrorCode();
                        throw CA2ServerAction.CodeToMsg(CA2DataType.CGHCAATL_A, rtn); // throw "所有讀卡機找不到 HC/HPC/HOP 卡片，請插入卡片" + GetErrorMessage(rtn);
                    }
                } //End If
            } else if (run_component == "ServiSign_" + osName2) {
                my_Reader = CGHCAATL.HCA_GetReaderNames(0);
                if (my_Reader == null) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGHCAATL_A, CGHCAATL.HCA_GetErrorCode()); //throw "找不到可使用的讀卡機! " + GetErrorMessage(CGHCAATL.HCA_GetErrorCode());
                } else {
                    readers = my_Reader.toArray();
                    this_card_usage = -1; //使用中卡片預設值
                    for (i = 0; i < readers.length; i++) {
                        reader = readers[i];
                        this_readername = reader;
                        CGHCAATL.HCA_GetHCBasicDataByReader(this_readername, 0);
                        if (CGHCAATL.HCA_GetErrorCode() == 0) {
                            this_card_usage = CARD_USAGE_HC;
                            return "健保IC卡";
                        }
                        type = CGHCAATL.HCA_GetCardType(this_readername);
                        if (CGHCAATL.HCA_GetErrorCode() == 0) {
                            if (type == 0) {
                                this_card_usage = CARD_USAGE_HOP;
                                return "醫事機構卡";
                            } else if (type == 1) {
                                this_card_usage = CARD_USAGE_HPC;
                                return "醫事人員卡";
                            }
                        }
                    } // end for
                    //document.queryform.READER.value = this_readername;
                    if (this_card_usage == CARD_USAGE_UNKNOWN) {
                        rtn = CGHCAATL.HCA_GetErrorCode();
                        throw CA2ServerAction.CodeToMsg(CA2DataType.CGHCAATL_A, rtn); // throw "所有讀卡機找不到 HC/HPC/HOP 卡片，請插入卡片" + GetErrorMessage(rtn);
                    }
                } //End If
            } //End If 元件判斷ServiSign
        // } catch (err) {
        //     //alert(err.name);// 警報 'Error'
        //     //alert(err.message); // 警報 'The message' 或 JavaScript 錯誤訊息
        //     throw err;
        // }
    }

    this.CGHCA_GetHOPBasicDataStringByReader = function () {
        //公用參數區-----
        var HOPBasicDataString;
        var rtn;
        //---------------
        if (run_component == "Unknown" || run_component == "ServiSign_Linux" || run_component == "ServiSign_Mac") { throw "目前HCA元件僅支援Windows請用Windows執行元件"; }
        // try {
            if (run_component == "ActiveX") {   //ActiveX元件需要先getElementByID
                // FSHCAATL = document.getElementById("FSHCAATL");
                HOPBasicDataString = FSHCAATL.FSHCA_GetHOPBasicDataStringByReader(this_readername, 0);
                rtn = FSHCAATL.FSHCA_GetErrorCode();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGHCAATL_A, rtn);
                }
                return HOPBasicDataString;
            } else if (run_component == "ServiSign_" + osName2) {
                HOPBasicDataString = CGHCAATL.HCA_GetHOPBasicDataStringByReader(this_readername, 0);
                rtn = CGHCAATL.HCA_GetErrorCode();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGHCAATL_A, rtn);
                }
                return HOPBasicDataString;
            }
        // } catch (err) {
        //     throw err;
        // }
    }

    this.CGHCA_GetHPCBasicDataStringByReader = function () {
        //公用參數區-----
        var HPCBasicDataString;
        var rtn;
        //---------------
        if (run_component == "Unknown" || run_component == "ServiSign_Linux" || run_component == "ServiSign_Mac") { throw "目前HCA元件僅支援Windows請用Windows執行元件"; }
        // try {
            if (run_component == "ActiveX") {   //ActiveX元件需要先getElementByID
                // FSHCAATL = document.getElementById("FSHCAATL");
                HPCBasicDataString = FSHCAATL.FSHCA_GetHPCBasicDataStringByReader(this_readername, 0);
                rtn = FSHCAATL.FSHCA_GetErrorCode();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGHCAATL_A, rtn);
                }
                return HPCBasicDataString;
            } else if (run_component == "ServiSign_" + osName2) {
                HPCBasicDataString = CGHCAATL.HCA_GetHPCBasicDataStringByReader(this_readername, 0);
                rtn = CGHCAATL.HCA_GetErrorCode();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGHCAATL_A, rtn);
                }
                return HPCBasicDataString;
            }
        // } catch (err) {
        //     throw err;
        // }
    }

    this.CGHCA_GetCertSubject = function (X509Cert) {	//輸入X509Cert Base64回傳憑證主旨
        //公用參數區-----
        var CertSubject;
        var rtn;
        //---------------
        if (run_component == "Unknown" || run_component == "ServiSign_Linux" || run_component == "ServiSign_Mac") { throw "目前HCA元件僅支援Windows請用Windows執行元件"; }
        if (X509Cert == "") {
            throw "請輸入X509-Base64格式憑證";
        }
        // try {
            if (run_component == "ActiveX") {   //ActiveX元件需要先getElementByID
                // FSHCAATL = document.getElementById("FSHCAATL");
                CertSubject = FSHCAATL.FSHCA_GetCertSubject(X509Cert, 0);
                rtn = FSHCAATL.FSHCA_GetErrorCode();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGHCAATL_A, rtn);
                }
                return CertSubject;
            } else if (run_component == "ServiSign_" + osName2) {
                CertSubject = CGHCAATL.HCA_GetCertSubject(X509Cert, 0);
                rtn = CGHCAATL.HCA_GetErrorCode();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGHCAATL_A, rtn);
                }
                return CertSubject;
            }
        // } catch (err) {
        //     throw err;
        // }
    }

    this.CGHCA_GetReaderNamesString = function () {	//取得ReaderNameString
        //公用參數區-----
        var ReaderNamesString;
        var rtn;
        //---------------
        if (run_component == "Unknown" || run_component == "ServiSign_Linux" || run_component == "ServiSign_Mac") { throw "目前HCA元件僅支援Windows請用Windows執行元件"; }
        // try {
            if (run_component == "ActiveX") {   //ActiveX元件需要先getElementByID
                // FSHCAATL = document.getElementById("FSHCAATL");
                ReaderNamesString = FSHCAATL.FSHCA_GetReaderNamesString(0);
                rtn = FSHCAATL.FSHCA_GetErrorCode();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGHCAATL_A, rtn);
                }
                return ReaderNamesString;
            } else if (run_component == "ServiSign_" + osName2) {
                ReaderNamesString = CGHCAATL.HCA_GetReaderNamesString(0);
                rtn = CGHCAATL.HCA_GetErrorCode();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGHCAATL_A, rtn);
                }
                return ReaderNamesString;
            }
        // } catch (err) {
        //     throw err;
        // }
    }

    this.CGHCA_SignByReader = function (plaintext, PinCode) {	//取得ReaderNameString
        //公用參數區-----
        var rtn;
        var signature;
        var flag = 0;
        //algorithm = 0x02; // CG_ALGOR_SHA1
        var algorithm = 0x04; // CG_ALGOR_SHA256
        //---------------
        if (run_component == "Unknown" || run_component == "ServiSign_Linux" || run_component == "ServiSign_Mac") { throw "目前HCA元件僅支援Windows請用Windows執行元件"; }
        if (this_card_usage == CARD_USAGE_HC) {
            throw "卡片型態不符，此為健保IC卡";
        }
        if (this_readername.length == 0) {
            throw "讀卡機為空字串";
        }
        // try {
            if (run_component == "ActiveX") {   //ActiveX元件需要先getElementByID
                // FSHCAATL = document.getElementById("FSHCAATL");
                signature = FSHCAATL.FSHCA_SignByReader(this_readername,
							PinCode,
							plaintext,
							flag);
                rtn = FSHCAATL.FSHCA_GetErrorCode();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGHCAATL_A, rtn);
                }
                return signature;
            } else if (run_component == "ServiSign_" + osName2) {
                signature = CGHCAATL.HCA_SignByReader(this_readername,
							PinCode,
							plaintext,
							flag,
							algorithm);
                rtn = CGHCAATL.HCA_GetErrorCode();
                if (rtn != 0) {
                    throw CA2ServerAction.CodeToMsg(CA2DataType.CGHCAATL_A, rtn);
                }
                return signature;
            }
        // } catch (err) {
        //     throw err;
        // }
    }

    this.CGSCUtil_CGListReaders = function () {
        //公用參數區-----
        var readers;
        var rtn;
        //---------------
        if (run_component == "Unknown" || run_component == "ServiSign_Linux" || run_component == "ServiSign_Mac") { throw "目前讀卡機偵測元件僅支援Windows請用Windows執行元件"; }
        // try {
            if (run_component == "ActiveX") {   //ActiveX元件需要先getElementByID
                // FSSCUtil = document.getElementById("FSSCUtil");
                readers = [];
                rtn = FSSCUtil.FSListReaders();
                if (rtn == null) {
                    return readers; // throw "無已安裝的讀卡機存在,錯誤代碼:" + FSSCUtil.lastError;
                } else {
                    readers = rtn.toArray();
                    return readers;
                    /*let msg = "";
                    if (readers.length == 1) {
                    msg = readers[0];
                    } else {
                    for (var i = 0; i < readers.length; i++) {
                    msg += "reader:" + readers[i] + "|";
                    } // end for
                    }*/
                }
                //return msg;
            } else if (run_component == "ServiSign_" + osName2) {
                readers = [];
                rtn = CGSCUtil.CGListReaders();
                if (rtn == null) {
                    return readers; // throw "無已安裝的讀卡機存在,錯誤代碼:" + CGSCUtil.lastError;
                } else {
                    readers = rtn.toArray();
                    return readers;
                    /*let msg = "";
                    if (readers.length == 1) {
                    msg = readers[0];
                    } else {
                    for (var i = 0; i < readers.length; i++) {
                    msg += "reader:" + readers[i] + "|";
                    } // end for
                    }*/
                }
                //return msg;
            }
        // } catch (err) {
        //     throw err;
        // }
    }

    this.CGSCUtil_getATR = function () {
        //公用參數區-----
        var i;
        var ATR;
        var readers;
        var rtn;
        //---------------
        if (run_component == "Unknown" || run_component == "ServiSign_Linux" || run_component == "ServiSign_Mac") { throw "目前讀卡機偵測元件僅支援Windows請用Windows執行元件"; }
            if (run_component == "ActiveX") {   //ActiveX元件需要先getElementByID
                // FSSCUtil = document.getElementById("FSSCUtil");
                rtn = FSSCUtil.FSListReaders();
                if (rtn == null) {
                    throw "無已安裝的讀卡機存在,錯誤代碼:" + FSSCUtil.lastError;
                } else {
                    readers = rtn.toArray();
                    ATR = "";
                    if (readers.length == 1) {
                        ATR = FSSCUtil.getATR(readers[0]);
                    } else {
                        for (i = 0; i < readers.length; i++) {
                            ATR += FSSCUtil.getATR(readers[0]) + "|";
                        } // end for
                    }
                }
                return ATR;
            } else if (run_component == "ServiSign_" + osName2) {
                rtn = CGSCUtil.CGListReaders();
                if (rtn == null) {
                    throw "無已安裝的讀卡機存在,錯誤代碼:" + CGSCUtil.lastError;
                } else {
                    readers = rtn.toArray();
                    ATR = "";
                    if (readers.length == 1) {
                        ATR = CGSCUtil.getATR(readers[0]);
                    } else {
                        for (i = 0; i < readers.length; i++) {
                            ATR += CGSCUtil.getATR(readers[0]) + "|";
                        } // end for
                    }
                }
                return ATR;
            }
    }

    function GetServiSignErrorMessage(ErrCode) { //將錯誤代碼轉換為人看得懂的訊息   
        let msg = "";
        switch (ErrCode) {
            case 61001:
                msg = "[" + ErrCode + "] 一般性錯誤";
                break;
            case 61003:
                msg = "[" + ErrCode + "] 字元轉換成數字錯誤";
                break;
            case 61004:
                msg = "[" + ErrCode + "] 字元轉換成數字錯誤時 字元為空";
                break;
            case 61005:
                msg = "[" + ErrCode + "] 參數錯誤";
                break;
            case 61006:
                msg = "[" + ErrCode + "] SSL 通訊失敗";
                break;
            case 61007:
                msg = "[" + ErrCode + "] 元件過期";
                break;
            case 61201:
                msg = "[" + ErrCode + "] 元件版本格式錯誤";
                break;
            case 61202:
                msg = "[" + ErrCode + "] ServiSign 版本格式錯誤";
                break;
            case 61203:
                msg = "[" + ErrCode + "] ServiSign 版本錯誤";
                break;
            case 61204:
                msg = "[" + ErrCode + "] 元件版本錯誤";
                break;
            case 61902:
                msg = "[" + ErrCode + "] 無法找到元件檔案";
                break;
            case 61904:
                msg = "[" + ErrCode + "] 來源並非Localhost";
                break;
            case 61905:
                msg = "[" + ErrCode + "] 元件無法初始化";
                break;
            case 61906:
                msg = "[" + ErrCode + "] 元件無法存取";
                break;
            case 61908:
                msg = "[" + ErrCode + "] 元件尚未認證";
                break;
            default:
                msg = ErrCode
                break;
        }
        return msg;
    }

    // function installActiveXError(name) {
    //     throw "install ActiveX Error, " + name;
    // }

}

export default CGPkiSingleton;