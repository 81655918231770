//**********************************************************************************************************************************************************/''
//程式代號：CA2NatureHumanCard.js
//程式名稱：自然人憑證卡類別
//作業說明：在此類別中，撰寫與自然人憑證卡有關的業務邏輯。
// No.  YYYY/MM/DD  VER     AUTHOR      COMMENTS
//  1.  2022/10/17  1.0       KC         Create
//**********************************************************************************************************************************************************/''
import CA2Factory from './CA2Factory.js';
import CA2DataType from './CA2DataType.js';
import CA2ServerAction from './CA2ServerAction.js';
import CGBrowserDetect from '../CG/CGBrowserDetect.js';
import CGPkiSingleton from '../CG/CGPkiSingleton.js';
import CGServerAction from '../CG/CGServerAction.js';
import CGCertTypeOID from '../CG/CGCertTypeOID.js';

//自然人憑證卡
var CA2NatureHumanCard = function(PINCode, UserInputID) {
    var m_OsName = CGBrowserDetect.OS; // Windows, Mac, Linux

    //欄位.憑證卡種類
    this.CardType = function () { return "CA2NatureHumanCard"; }

    //欄位.產生晶片讀卡機
    this.CreateSmartCardReader = function () { m_DefaultReader = CA2Factory.CreateSmartCardReader(); m_DefaultReader.SetDefaultCard(this); return m_DefaultReader; }

    //欄位.畫面輸入的身分證號(ReadOnly)
    var m_UserInputID = UserInputID;

    //欄位.畫面輸入的PIN碼(ReadOnly)
    var m_PINCode = PINCode;
    this.GetPINCode = function () {
        if (m_PINCode == null) { throw CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1003); } //PIN碼未輸入
        return m_PINCode;
    }

    //欄位.預設讀卡機(Read,Write)
    var m_DefaultReader;
    this.GetDefaultReader = function () { if (m_DefaultReader == null) { m_DefaultReader = CA2Factory.CreateSmartCardReader(); m_DefaultReader.SetDefaultCard(this); } return m_DefaultReader; }
    this.SetDefaultReader = function (CardReader) { m_DefaultReader = CardReader; m_DefaultReader.SetDefaultCard(this); }

    //欄位.已經過驗證的身分證號(ReadOnly)
    var m_ID;
    this.ID = function () {
        if (m_UserInputID == null) {
            throw CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1004); //身分證號未輸入 
        }
        if (m_ID == null) { GetCardInfo(this); } return m_ID;
    }

    //欄位.身分證號後4碼(ReadOnly)
    var m_ID4;
    this.ID4 = function () { if (m_ID4 == null) { GetCardInfo(this); } return m_ID4; }

    //欄位.姓名(ReadOnly)
    var m_Name;
    this.Name = function () { if (m_Name == null) { GetCardInfo(this); } return m_Name; }

    //欄位.簽章憑證
    var m_X509SignatureCert;
    this.GetX509SignatureCert = function () { if (m_X509SignatureCert == null) { GetCardInfo(this); } return m_X509SignatureCert; }
  
    //欄位.卡片簽章值
    var m_SignData;
    this.SignData = function () { if (m_SignData == null) { GetCardInfo(this); } return m_SignData; }

    //欄位.判斷是否eID(Y：是eID，N：非eID)
    var m_IsEID;
    this.IsEID = function () {
        if (m_IsEID == null) {
            try {
                var api2 = CGPkiSingleton.Instance().CGEIDSVIAdapter();
                api2.EIDSetReader(this.GetDefaultReader().GetDefaultReaderName());
                // rtn = api2.EIDReadHouseholdData(0, 0);
                if (api2.GetErrorCode() == 0) {
                    m_IsEID = "Y";
                } else {
                    m_IsEID = "N";
                }
            } catch (e) {
                m_IsEID = "N";
            }
        }
        return m_IsEID;
    }
        
    //取得基本資料
    function GetCardInfo(Card) {
        //判斷讀卡機執行相對應的function
        switch (Card.GetDefaultReader().ReaderType()) {
            case "CA2SmartCardReader":
                try {
                    if (m_OsName == 'Windows') if (Card.GetDefaultReader().GetReaderArr().length == 0) throw CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1014); //找不到晶片讀卡機
                    GetCardInfoWithSmartCardReader(Card);
                    VerifyCard(Card);
                } catch (e) {
                    if (Object.prototype.toString.call(e) == "[object Object]") { e["ID"] = m_UserInputID; } //記錄失敗ID
                    throw e;
                }
                break;
            default:
                throw CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1001); //無此讀卡機程式邏輯
        }
    }

    //取得基本資料,使用晶片讀卡機
    function GetCardInfoWithSmartCardReader(Card) {
        m_X509SignatureCert = CGPkiSingleton.Instance().CGGPKI_EnumCerts("sign"); //簽章憑證

        //檢查是否使用正確的卡別。
        var certType = CGPkiSingleton.Instance().CGGPKI_GetCertTypeOID(m_X509SignatureCert, 0);
        if (certType != CGCertTypeOID.CG_CERT_TYPE_OID_1 && certType != CGCertTypeOID.CG_CERT_TYPE_OID_15) throw CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1022); //請使用自然人憑證卡

        m_ID = m_UserInputID; //使用者輸入身分證號

        m_ID4 = CGPkiSingleton.Instance().CGGPKI_GetTailOfCitizenID(m_X509SignatureCert); //身分證號後4碼

        //取得一組簽章Token（透過ajax至Server端取Token）
        var CrToken = CGServerAction.GetCrToken();

        //簽章
        var signature = CGPkiSingleton.Instance().CGGPKI_PKCS7_Sign(CrToken, Card.GetPINCode());
        var signature2 = CGPkiSingleton.Instance().CGGPKI_PKCS7_Sign(m_UserInputID, Card.GetPINCode()); 
        m_SignData = signature + "," + signature2;
    }

    //執行驗卡程序
    function VerifyCard(Card) {
        //比對畫面輸入的身分證與憑證的後4碼是否一致，如果出錯拋出錯誤
        if (m_UserInputID.substring(6) != Card.ID4()) throw CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1005); //身分證號與卡片不符 
    } //end of VerifyCard
}

export default CA2NatureHumanCard;