//**********************************************************************************************************************************************************/''
//程式代號：CA2Common.js
//程式名稱：憑證卡Javascript函式庫共用函式
//作業說明：在此檔案中，撰寫本專案共用函式
// No.  YYYY/MM/DD  VER     AUTHOR      COMMENTS
//  1.  2022/10/17  1.0       KC         Create
//**********************************************************************************************************************************************************/''
var CA2Common = {
    DownloadFileUrl: function () {
        return "https://med.nhi.gov.tw";
    }
}

//截取含中文字串
if(!String.prototype.substringch){
    String.prototype.substringch = function (startp, endp) {
        var i;
        var j;
        var c = 0;
        var unicode = 0;
        var rstr = '';
        var len = this.length;
        var sblen = getChars(this);

        if (startp < 0) {
            startp = sblen + startp;
        }

        if (endp < 1) {
            endp = sblen + endp;
        }

        for (i = 0; i < len; i++) {
            if (c >= startp) {
                break;
            }
            unicode = this.charCodeAt(i);
            if (unicode < 127) {
                c += 1;
            } else {
                c += 2;
            }
        }

        for (j = i; j < len; j++) {
            unicode = this.charCodeAt(j);
            if (unicode < 127) {
                c += 1;
            } else {
                c += 2;
            }
            rstr += this.charAt(j);

            if (c >= endp) {
                break;
            }
        }

        return rstr;

        function getChars(str) {
            var i = 0;
            var c = 0.0;
            var unicode = 0;
            var len = 0;

            if (str == null || str == "") {
                return 0;
            }
            len = str.length;
            for (i = 0; i < len; i++) {
                unicode = str.charCodeAt(i);
                if (unicode < 127) {
                    c += 1;
                } else {
                    c += 2;
                }
            }
            return c;
        }

        // function sb_strlen(str) {
        //     return getChars(str);
        // }
    }
}

//去除多餘前面文字
if (!String.prototype.trimleft) {
    String.prototype.trimleft = function () { return this.replace(/^\s+/, ''); }
}

//去除多餘後面文字
if (!String.prototype.trimright) {
    String.prototype.trimright = function () { return this.replace(/\s+$/, ''); }
}

export default CA2Common;
