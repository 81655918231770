//**********************************************************************************************************************************************************/''
//程式代號：CA2.js
//程式名稱：憑證卡Javascript函式庫js檔
//作業說明：此為憑證卡Javascript函式庫所有要Include的javascript檔案
// No.  YYYY/MM/DD  VER     AUTHOR      COMMENTS
//  1.  2022/10/17  1.0       KC         Create
//**********************************************************************************************************************************************************/''
import CA2Factory from './CA2/CA2Factory.js';
import CA2Message from './CA2/CA2Message.js';
import CGBrowserDetect from './CG/CGBrowserDetect.js';

var CA2 = {
	init: function(){
		this.CA2Factory = CA2Factory;
		this.CA2Message = CA2Message;
		this.CGBrowserDetect = CGBrowserDetect;
	}
}
CA2.init();

export default CA2;