var CGBrowserDetect = {
    init: function () {
        this.browser = this.searchString(this.dataBrowser) || "An unknown browser";
        this.version = this.searchVersion(navigator.userAgent)
			|| this.searchVersion(navigator.appVersion)
			|| "an unknown version";
        this.OS = this.searchString(this.dataOS) || "an unknown OS";
    },
    searchString: function (data) {
        for (var i = 0; i < data.length; i++) {
            var dataString = data[i].string;
            var dataProp = data[i].prop;
            this.versionSearchString = data[i].versionSearch || data[i].identity;
            if (dataString) {
                if (dataString.indexOf(data[i].subString) != -1)
                    return data[i].identity;
            }
            else if (dataProp)
                return data[i].identity;
        }
    },
    searchVersion: function (dataString) {
        var index = dataString.indexOf(this.versionSearchString);
        if (index == -1) return;
        var tmp = dataString.substring(index + this.versionSearchString.length + 1);
        index = tmp.indexOf(" ");
        if (index == -1) index = tmp.length;
        return tmp.substring(0, index);
    },
    dataBrowser: [
		{
			string: navigator.userAgent,
			subString: "Chrome",
			identity: "Chrome"
		},
		{ string: navigator.userAgent,
			subString: "OmniWeb",
			versionSearch: "OmniWeb/",
			identity: "OmniWeb"
		},
		{
			string: navigator.vendor,
			subString: "Apple",
			identity: "Safari",
			versionSearch: "Version"
		},
		{
			prop: window.opera,
			identity: "Opera"
		},
		{
			string: navigator.vendor,
			subString: "iCab",
			identity: "iCab"
		},
		{
			string: navigator.vendor,
			subString: "KDE",
			identity: "Konqueror"
		},
		{
			string: navigator.userAgent,
			subString: "Firefox",
			identity: "Firefox"
		},
		{
			string: navigator.vendor,
			subString: "Camino",
			identity: "Camino"
		},
		{		// for newer Netscapes (6+)
			string: navigator.userAgent,
			subString: "Netscape",
			identity: "Netscape"
		},
		{
			string: navigator.userAgent,
			subString: "MSIE",
			identity: "Explorer",
			versionSearch: "MSIE"
		},
		{
			string: navigator.userAgent,
			subString: "Trident",
			identity: "Explorer",
			versionSearch: "rv"
		},
		{
			string: navigator.userAgent,
			subString: "Gecko",
			identity: "Mozilla",
			versionSearch: "rv"
		},
		{ 		// for older Netscapes (4-)
			string: navigator.userAgent,
			subString: "Mozilla",
			identity: "Netscape",
			versionSearch: "Mozilla"
		}
	],
    dataOS: [
		{
			string: navigator.platform,
			subString: "Win",
			identity: "Windows"
		},
		{
			string: navigator.platform,
			subString: "Mac",
			identity: "Mac"
		},
		{
			string: navigator.userAgent,
			subString: "iPhone",
			identity: "iPhone/iPod"
		},
		{
			string: navigator.platform,
			subString: "Linux",
			identity: "Linux"
		}
	],
    detectOS: function () {
        var os, ua = navigator.userAgent;
        if (ua.match(/Win(dows )?NT 6\.1/)) {
            os = "Windows 7";               // Windows 7
        }
        else if (ua.match(/Win(dows )?NT 6\.0/)) {
            os = "Windows Vista";               // Windows Vista
        }
        else if (ua.match(/Win(dows )?NT 5\.2/)) {
            os = "Windows Server 2003";         // Windows Server 2003
        }
        else if (ua.match(/Win(dows )?(NT 5\.1|XP)/)) {
            os = "Windows XP";              // Windows XP
        }
        else if (ua.match(/Win(dows )? (9x 4\.90|ME)/)) {
            os = "Windows ME";              // Windows ME
        }
        else if (ua.match(/Win(dows )?(NT 5\.0|2000)/)) {
            os = "Windows 2000";                // Windows 2000
        }
        else if (ua.match(/Win(dows )?98/)) {
            os = "Windows 98";              // Windows 98
        }
        else if (ua.match(/Win(dows )?NT( 4\.0)?/)) {
            os = "Windows NT 4.0";              // Windows NT 4.0
        }
        else if (ua.match(/Win(dows )?95/)) {
            os = "Windows 95";              // Windows 95
        }
        else if (ua.match(/Mac|PPC/)) {
            os = "Mac OS";                  // Macintosh
        }
        else if (ua.match(/Linux/)) {
            os = "Linux";                   // Linux
        }
        else if (ua.match(/(Free|Net|Open)BSD/)) {
            os = RegExp.$1 + "BSD";             // BSD
        }
        else if (ua.match(/SunOS/)) {
            os = "Solaris";                 // Solaris
        }
        else {
            os = "Unknown";                 // Other OS
        }
        return os;
    }

};
CGBrowserDetect.init();
//document.write('Browser: '+CGBrowserDetect.browser+'<br>version: '+CGBrowserDetect.version+'<br>OS: '+CGBrowserDetect.OS); 

/*function getBrowser(){
return CGBrowserDetect.browser;
}*/

/*function getVersion(){
return CGBrowserDetect.version;
}*/

/*function getOS(){
return CGBrowserDetect.OS;
}*/

export default CGBrowserDetect;