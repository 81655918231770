//**********************************************************************************************************************************************************/''
//程式代號：CA2ShangCard.js
//程式名稱：工商憑證卡類別
//作業說明：在此類別中，撰寫與工商憑證卡有關的業務邏輯。
// No.  YYYY/MM/DD  VER     AUTHOR      COMMENTS
//  1.  2022/10/17  1.0       KC         Create
//**********************************************************************************************************************************************************/''
import CA2Factory from './CA2Factory.js';
import CA2DataType from './CA2DataType.js';
import CA2ServerAction from './CA2ServerAction.js';
import CGBrowserDetect from '../CG/CGBrowserDetect.js';
import CGPkiSingleton from '../CG/CGPkiSingleton.js';
import CGServerAction from '../CG/CGServerAction.js';
import CGCertType from '../CG/CGCertType.js';

//工商憑證卡
var CA2ShangCard = function(PINCode) {
    var m_OsName = CGBrowserDetect.OS; // Windows, Mac, Linux

    //欄位.憑證卡種類
    this.CardType = function () { return "CA2ShangCard"; }

    //欄位.產生晶片讀卡機
    this.CreateSmartCardReader = function () { m_DefaultReader = CA2Factory.CreateSmartCardReader(); m_DefaultReader.SetDefaultCard(this); return m_DefaultReader; }

    //欄位.畫面輸入的PIN碼(ReadOnly)
    var m_PINCode = PINCode;
    this.GetPINCode = function () {
        if (m_PINCode == null) { throw CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1003); } //PIN碼未輸入
        return m_PINCode;
    }

    //欄位.預設讀卡機(Read,Write)
    var m_DefaultReader;
    this.GetDefaultReader = function () { if (m_DefaultReader == null) { m_DefaultReader = CA2Factory.CreateSmartCardReader(); m_DefaultReader.SetDefaultCard(this); } return m_DefaultReader; }
    this.SetDefaultReader = function (CardReader) { m_DefaultReader = CardReader; m_DefaultReader.SetDefaultCard(this); }

    //欄位.統一編號(ReadOnly)
    var m_ID;
    this.ID = function () { if (m_ID == null) { GetCardInfo(this); } return m_ID; }

    //欄位.簽章憑證
    var m_X509SignatureCert;
    this.GetX509SignatureCert = function () { if (m_X509SignatureCert == null) { GetCardInfo(this); } return m_X509SignatureCert; }

    //欄位.卡片簽章值
    var m_SignData;
    this.SignData = function () { if (m_PINCode == null) { throw "無法讀取簽章值"; } if (m_SignData == null) { GetCardInfo(this); } return m_SignData; }

    //欄位.名稱
    var m_Name;
    this.Name = function () { if (m_Name == null) { GetCardInfo(this); } return m_Name; }

    //取得基本資料
    function GetCardInfo(Card) {
        //判斷讀卡機執行相對應的function
        switch (Card.GetDefaultReader().ReaderType()) {
            case "CA2SmartCardReader":
                if (m_OsName == 'Windows') if (Card.GetDefaultReader().GetReaderArr().length == 0) throw CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1014); //找不到晶片讀卡機
                try {
                    GetCardInfoWithSmartCardReader(Card);
                } catch (e) {
                    if (Object.prototype.toString.call(e) == "[object Object]") { e["ID"] = m_ID; } //記錄失敗ID
                    throw e;
                }
                break;
            default:
                throw CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1001); //無此讀卡機程式邏輯
        }
    }

    //取得基本資料,使用晶片讀卡機
    function GetCardInfoWithSmartCardReader(Card) {
        m_X509SignatureCert = CGPkiSingleton.Instance().CGGPKI_EnumCerts("sign"); //簽章憑證

        //檢查是否使用正確的卡別。
        var certType = CGPkiSingleton.Instance().CGGPKI_GetCertType(m_X509SignatureCert, 0);
        if (certType != CGCertType.CG_CERT_TYPE_5 && certType != CGCertType.CG_CERT_TYPE_8 && certType != CGCertType.CG_CERT_TYPE_9 && certType != CGCertType.CG_CERT_TYPE_16 && certType != CGCertType.CG_CERT_TYPE_17) throw CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1023); //請使用工商憑證卡

        m_ID = CGPkiSingleton.Instance().CGGPKI_GetOrganizationID(m_X509SignatureCert); //使用API讀取-統一編號

        var name1 = "";
        var arr = CGPkiSingleton.Instance().CGGPKI_CAPICertGetSubject(m_X509SignatureCert).split('\r\n'); //使用API讀取憑證主旨
        arr.forEach(function (item) {
            try { if (["L", "O", "OU"].includes(item.split("=")[0].toUpperCase())) name1 += item.split("=")[1]; } catch(e) { /**/ }
        });
        m_Name = name1;

        //取簽章流程
        if (m_PINCode != null) {
            //取得一組簽章Token（透過ajax至Server端取Token）
            var CrToken = CGServerAction.GetCrToken();

            //簽章
            var signature = CGPkiSingleton.Instance().CGGPKI_PKCS7_Sign(CrToken, Card.GetPINCode());
            m_SignData = signature;
        }
    }
}

export default CA2ShangCard;