//**********************************************************************************************************************************************************/''
//程式代號：CA2SmartCardReader.js
//程式名稱：晶片讀卡機類別
//作業說明：在此類別中，撰寫與晶片讀卡機有關的業務邏輯。
// No.  YYYY/MM/DD  VER     AUTHOR      COMMENTS
//  1.  2022/10/17  1.0       KC         Create
//**********************************************************************************************************************************************************/''

import CGPkiSingleton from '../CG/CGPkiSingleton.js';

//健保卡讀卡機
var CA2SmartCardReader = function() {
    //欄位.讀卡機種類
    this.ReaderType = function () { return "CA2SmartCardReader"; }

    //欄位.預設憑證卡
    var m_DefaultCard;
    this.GetDefaultCard = function () { return m_DefaultCard; }
    this.SetDefaultCard = function (Card) { m_DefaultCard = Card; } //設定預設憑證卡

    //欄位.讀卡機陣列
    var m_ReaderArr;
    this.GetReaderArr = function () { if (m_ReaderArr == null) { m_ReaderArr = this.ScanReaderName(); } return m_ReaderArr; }

    //欄位.預設讀卡機名稱
    var m_DefaultReaderName;
    this.GetDefaultReaderName = function () { if (m_DefaultReaderName == null) { this.SetDefaultReaderName(this.GetReaderArr()[0]); } return m_DefaultReaderName; }
    this.SetDefaultReaderName = function (ReaderName) {
        m_DefaultReaderName = ReaderName; //設定讀卡機名稱
    }

    //欄位.預設讀卡機索引
    this.GetDefaultReaderIndex = function () { return this.GetReaderIndex(m_DefaultReaderName); }

    //欄位.讀卡機索引
    this.GetReaderIndex = function (ReaderName) {
        var arrReaders = this.GetReaderArr();
        for (var i = 0; i < arrReaders.length; i++) {
            if (arrReaders[i] == ReaderName) { return i; }
        }
        return null;
    }

    //讀卡機名稱掃瞄程序
    this.ScanReaderName = function () {
        //判斷讀卡機執行相對應的function
        var m_HCA;
        switch (m_DefaultCard.CardType()) {
            case "CA2HealthCard":
                throw "無此邏輯待補";
            case "CA2OrganizationCard":
            case "CA2PersonalCard":
                m_HCA = "HCA";
                break;
        }
        var api = CGPkiSingleton.Instance(m_HCA); //該3種卡只能執行在Windows的環境，以此作判斷
        var arrReaders = [];
        var strReaders = api.CGSCUtil_CGListReaders();
        if (strReaders.length != 0) arrReaders = strReaders;
        return arrReaders;
    }
}

export default CA2SmartCardReader;

