var getCGHCAATLAdapterObj = function ()
{	
	var CGShareUrl = "";
	var ServiSignErrorCode = 61001;
	var LibName = "CGHCAATLAdapter";								//元件名稱
	var minServiSignVersion = "1.0.16.0418";					//最低ServiSign版本需求
	var minLibVersion = "0.0.0.0";								//最低元件版本需求
	var LibDir = "CG";									//安裝資料夾
	// var JSVersion = "1.0.16.0624";
	
	// var ServiSignFlag_PathProtect    = true;					//路徑保護
	//DataObj.pfxpath_servisignflag = ServiSignFlag_PathProtect;
    //DataObj.pfxpath = pfxpath.replace(/\\/g, "*");

	//DataObj.topuidetect = true;							//設定UI置頂
	function ServiSign_GetErrorCode(){
		return ServiSignErrorCode;
	}
	
	function CGServiSignConnectError(){
		ServiSignErrorCode = 61006;//ServiSign_RTN_CONNECTION_ERROR
	}
	
	function CGServiSignError(){
		ServiSignErrorCode = 61202;//ServiSign_JS_VERSION_FORMAT_ERROR  61202  61202
	}
	function LibError(){
		ServiSignErrorCode = 61201;//ServiSign_JS_LIB_VERSION_FORMAT_ERROR 61201
	}
	function CGServiSignVersionTooLow(){
		ServiSignErrorCode = 61203;//ServiSign_JS_SERVISIGN_VERSION_ERROR 61203
	}
	function LibVersionTooLow(){
		ServiSignErrorCode = 61204;//ServiSign_JS_LIB_VERSION_ERROR   61204
	}
	var Base64Encode = function (input) {
		return encodeURIComponent(window.btoa(unescape(encodeURIComponent(input))));
	}
	var Base64Decode = function (input) {
		if(input == undefined) return "";
		return decodeURIComponent(escape(window.atob(input)));
	}
	function FixArray(inputArray){
		var array = inputArray.split(";");
		array.splice(array.length - 1, array.length);
		array.toArray = function(){
			return this;
		}
		return array;
	}
	/*error  'KeepAlive' is defined but never used  no-unused-vars
	function KeepAlive(DataObj){
		var temp = "";
		DataObj.KeepAlive = true;
		DataObj.topuidetect = true;
		do{
			temp = ServiSignObj.Send(DataObj);
		}while(temp == "heartbeat");
		return temp;
	}*/
	function getServiSignObj()
	{
		var Url_Part1 = 'https://localhost:';
		var portList = new Array(56306, 56316, 56336);
		var SessionID ="";
		var WorkURL = "";
		
		var realServiSignVersion = "";
		var realLibVersion = "";
		
		var ServiSignObj = 
		{
			senData : function(url, DataObj){
				var resultObj;		
				var xhr = new XMLHttpRequest();
				xhr.open('POST',url,false);
				xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
				//error  'event' is defined but never used      no-unused-vars
				var onLoadHandler = function(){
					try {
						resultObj = JSON.parse(this.responseText);
					}
					catch(err) {
						if(this.responseText != undefined){
							resultObj = new Object();
							resultObj.result = this.responseText.substring(11, this.responseText.length - 2);
						}
						else{
							resultObj = "";
						}
					}
				}
				xhr.onload = onLoadHandler;

				DataObj.comname = LibName;
				DataObj.libdir = LibDir;
				DataObj.minserverversion = minServiSignVersion;
				DataObj.sessionid = SessionID;
								
				var readyDataObj = "Parameter=" + Base64Encode(JSON.stringify(DataObj));
				
				try {
					xhr.send(readyDataObj);
				}
				catch(err) {
					return undefined;
				}
				return resultObj;
			},
			TryPort : function(){
				if(CGShareUrl == "fail") return false;
				else if(CGShareUrl != "") WorkURL = CGShareUrl;
				
				var resultObj;
				var DataObj = new Object();
				DataObj.functionname = "ServiSignEcho";
				DataObj.minlibversion = minLibVersion;
				DataObj.url = document.URL.substring(0, document.URL.indexOf("/", document.URL.indexOf("/") + 2));
				
				if(WorkURL == ""){
					for (var i = 0; i < portList.length; i++) {
						var url = Url_Part1 + portList[i];
						resultObj = this.senData(url, DataObj);
						if(resultObj != undefined){
							WorkURL = url;
							CGShareUrl = url;
							break;
						}
					}
				}
				else{
					resultObj = this.senData(WorkURL, DataObj);
				}
				if(resultObj == undefined){
					CGServiSignConnectError();
					return false;
				}
				ServiSignErrorCode = 0;
				if(WorkURL != ""){
					var VersionToVersion = function(input){
						var result = input;
						result = result.replace(" ","");
						result = result.replace(".",",");
						result = result.replace(".",",");
						result = result.replace(".",",");
						return result.split(",");
					};
					if(resultObj.ServerVersion == undefined){
						ServiSignErrorCode = Base64Decode(resultObj.result);
						return false;
					}
					
					realServiSignVersion = Base64Decode(resultObj.ServerVersion);
					realLibVersion = Base64Decode(resultObj.LibVersion);
					
					var realServerVersionArray = VersionToVersion(realServiSignVersion);
					var realLibVersionArray = VersionToVersion(realLibVersion);
					
					var minServiSignVersionArray = VersionToVersion(minServiSignVersion);
					var minLibVersionArray = VersionToVersion(minLibVersion);
					
					if(realServerVersionArray.length != minServiSignVersionArray.length){
						CGServiSignError();
						return false;
					}
					if(realLibVersionArray.length != minLibVersionArray.length){
						LibError();
						return false;
					}
						
					var VerifyVersion = true;
					//'i' is already defined                 no-redeclare
					for(i = 0 ; i < minServiSignVersionArray.length ; i++){
						if(parseInt(realServerVersionArray[i]) < parseInt(minServiSignVersionArray[i])){
							CGServiSignVersionTooLow();
							VerifyVersion = false;
							break;
						}
						else if(parseInt(realServerVersionArray[i]) > parseInt(minServiSignVersionArray[i])){
							break;
						}
					}
					//'i' is already defined                 no-redeclare
					for(i = 0 ; i < minLibVersionArray.length ; i++){
						if(parseInt(realLibVersionArray[i]) < parseInt(minLibVersionArray[i])){
							LibVersionTooLow();
							VerifyVersion = false;
							break;
						}
						else if(parseInt(realLibVersionArray[i]) > parseInt(minLibVersionArray[i])){
							break;
						}
					}
					if(SessionID == ""){
						SessionID = Base64Decode(resultObj.SessionID);
					}
					return VerifyVersion;
				}
				else{
					CGShareUrl = "fail";
					ServiSignErrorCode = 61001;
					return false;
				}
			},
			Send : function(DataObj){
				DataObj.comname = LibName;
				var resultObj = this.senData(WorkURL, DataObj);
				return Base64Decode(resultObj.result);
			},
			getCGServiSignVersion : function(){
				return realServiSignVersion;
			},
			getLibVersion : function(){
				return realLibVersion;
			},
			ServiSigninit : function()
			{
				var x = new XMLHttpRequest();
				if( window.FormData === undefined || x == undefined){
					alert("This Browser Does NOT support HTML5");
					return false;
				}
				return this.TryPort();
			},
			ServiSignClose : function()
			{
				var DataObj = new Object();
				DataObj.functionname = "ServiSignClose";
				DataObj.minlibversion = minLibVersion;
				return this.Send(DataObj);
			}
		};
		return ServiSignObj;
	}
	var ServiSignObj = getServiSignObj();
	var ServiSignInterface = 
	{
		GetServiSignVersion : function()
		{
			return ServiSignObj.getCGServiSignVersion();
		},
		GetLibVersion : function()
		{
			return ServiSignObj.getLibVersion();
		},
		HCA_GetErrorCode : function(){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetErrorCode";
			return parseInt(ServiSignObj.Send(DataObj));
		},
		HCA_Encode : function(strData, strPemTitle){
			var DataObj = new Object();
			DataObj.functionname = "HCA_Encode";
			DataObj.strdata = strData;
			DataObj.strpemtitle = strPemTitle;
			return ServiSignObj.Send(DataObj);
		},
		HCA_GetCardSN : function(){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetCardSN";
			return ServiSignObj.Send(DataObj);
		},
		HCA_GetCardType : function(readername){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetCardType";
			DataObj.readername = readername;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		HCA_GetCardInfo : function(){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetCardInfo";
			return FixArray(ServiSignObj.Send(DataObj));
		},
		HCA_GetCertSerialNumber : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetCertSerialNumber";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		HCA_GetCertSubject : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetCertSubject";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		HCA_GetCertIssuer : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetCertIssuer";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		HCA_GetCertNotBefore : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetCertNotBefore";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		HCA_GetCertNotAfter : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetCertNotAfter";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		HCA_GetCertDigest : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetCertDigest";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		HCA_Verify : function(strCert, strSignature, pData, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_Verify";
			DataObj.strcert = strCert;
			DataObj.strsignature = strSignature;
			DataObj.pdata = pData;
			DataObj.iflags = iFlags;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		HCA_Decode : function(strEncoded, strPemTitle){
			var DataObj = new Object();
			DataObj.functionname = "HCA_Decode";
			DataObj.strencoded = strEncoded;
			DataObj.strpemtitle = strPemTitle;
			return ServiSignObj.Send(DataObj);
		},
		HCA_GetRecord : function(readername, strLabel){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetRecord";
			DataObj.readername = readername;
			DataObj.strlabel = strLabel;
			return ServiSignObj.Send(DataObj);
		},
		HCA_ListReaders : function(){
			var DataObj = new Object();
			DataObj.functionname = "HCA_ListReaders";
			return FixArray(ServiSignObj.Send(DataObj));
		},
		HCA_GetHCBasicData : function(){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetHCBasicData";
			return FixArray(ServiSignObj.Send(DataObj));
		},
		HCA_GetReaderName : function(flag){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetReaderName";
			DataObj.flag = flag;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		HCA_HPCBasicDataByReader : function(readername, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_HPCBasicDataByReader";
			DataObj.readername = readername;
			DataObj.iflags = iFlags;
			return FixArray(ServiSignObj.Send(DataObj));
		},
		HCA_GetCertificateByReader : function(readername, pincode, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetCertificateByReader";
			DataObj.readername = readername;
			DataObj.pincode = pincode;
			DataObj.iflags = iFlags;
			DataObj.topuidetect = true;
			return ServiSignObj.Send(DataObj);
		},
		HCA_SignByReader : function(readername, pincode, bData, iFlags, iHashFlag){
			var DataObj = new Object();
			DataObj.functionname = "HCA_SignByReader";
			DataObj.readername = readername;
			DataObj.pincode = pincode;
			DataObj.bdata = bData;
			DataObj.iflags = iFlags;
			DataObj.ihashflag = iHashFlag;
			DataObj.topuidetect = true;
			return ServiSignObj.Send(DataObj);
		},
		HCA_GetHCBasicDataByReader : function(readername, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetHCBasicDataByReader";
			DataObj.readername = readername;
			DataObj.iflags = iFlags;
			return FixArray(ServiSignObj.Send(DataObj));
		},
		HCA_GetReaderNames : function(iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetReaderNames";
			DataObj.iflags = iFlags;
			return FixArray(ServiSignObj.Send(DataObj));
		},
		HCA_GetHOPBasicDataByReader : function(readername, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetHOPBasicDataByReader";
			DataObj.readername = readername;
			DataObj.iflags = iFlags;
			return FixArray(ServiSignObj.Send(DataObj));
		},
		HCA_PureSignByReader : function(readername, pincode, bData, iFlags, iHashFlag){
			var DataObj = new Object();
			DataObj.functionname = "HCA_PureSignByReader";
			DataObj.readername = readername;
			DataObj.pincode = pincode;
			DataObj.bdata = bData;
			DataObj.iflags = iFlags;
			DataObj.ihashflag = iHashFlag;
			return ServiSignObj.Send(DataObj);
		},
		HCA_GetRetryCounter : function(){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetRetryCounter";
			return parseInt(ServiSignObj.Send(DataObj));
		},
		HCA_GetReaderNamesString : function(iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetReaderNamesString";
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		HCA_GetHOPBasicDataStringByReader : function(readername, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetHOPBasicDataStringByReader";
			DataObj.readername = readername;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		HCA_GetHCBasicDataStringByReader : function(readername, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetHCBasicDataStringByReader";
			DataObj.readername = readername;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		HCA_GetHPCBasicDataStringByReader : function(readername, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetHPCBasicDataStringByReader";
			DataObj.readername = readername;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		HCA_GetCardInfoByReader : function(readername, iFlag){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetCardInfoByReader";
			DataObj.readername = readername;
			DataObj.iflag = iFlag;
			return FixArray(ServiSignObj.Send(DataObj));
		},
		HCA_GeneratePKCS7 : function(signature, cert, data, flag, iHashFlag){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GeneratePKCS7";
			DataObj.signature = signature;
			DataObj.cert = cert;
			DataObj.data = data;
			DataObj.flag = flag;
			DataObj.ihashflag = iHashFlag;
			return ServiSignObj.Send(DataObj);
		},
		SetCodePage : function(codepage){
			var DataObj = new Object();
			DataObj.functionname = "SetCodePage";
			DataObj.codepage = codepage;
			return ServiSignObj.Send(DataObj);
		},
		HCA_Encrypt : function(strCert, bData, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_Encrypt";
			DataObj.strcert = strCert;
			DataObj.bdata = bData;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		HCA_DecryptByReader : function(readername, pincode, bCipher, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_DecryptByReader";
			DataObj.readername = readername;
			DataObj.pincode = pincode;
			DataObj.bcipher = bCipher;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		HCA_SignInitByReader : function(readername, pincode){
			var DataObj = new Object();
			DataObj.functionname = "HCA_SignInitByReader";
			DataObj.readername = readername;
			DataObj.pincode = pincode;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		HCA_SignUpdate : function(bData, iFlags, iHashFlag){
			var DataObj = new Object();
			DataObj.functionname = "HCA_SignUpdate";
			DataObj.bdata = bData;
			DataObj.iflags = iFlags;
			DataObj.ihashflag = iHashFlag;
			return ServiSignObj.Send(DataObj);
		},
		HCA_SignFinal : function(){
			var DataObj = new Object();
			DataObj.functionname = "HCA_SignFinal";
			return parseInt(ServiSignObj.Send(DataObj));
		},
		HCA_XMLSignTemplateByReader : function(readername, pincode, bstrXML, bstrSignId, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_XMLSignTemplateByReader";
			DataObj.readername = readername;
			DataObj.pincode = pincode;
			DataObj.bstrxml = bstrXML;
			DataObj.bstrsignid = bstrSignId;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		HCA_XMLSignTemplateUpdate : function(bstrXML, bstrSignId, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_XMLSignTemplateUpdate";
			DataObj.bstrxml = bstrXML;
			DataObj.bstrsignid = bstrSignId;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		HCA_XMLSignTemplate2FileByReader : function(readername, pincode, bstrXML, bstrSignId, bstrOutFIle, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_XMLSignTemplate2FileByReader";
			DataObj.readername = readername;
			DataObj.pincode = pincode;
			DataObj.bstrxml = bstrXML;
			DataObj.bstrsignid = bstrSignId;
			DataObj.bstroutfile = bstrOutFIle;
			DataObj.iflags = iFlags;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		HCA_XMLSignTemplate2FileUpdate : function(bstrXML, bstrSignId, bstrOutFIle, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "HCA_XMLSignTemplate2FileUpdate";
			DataObj.bstrxml = bstrXML;
			DataObj.bstrsignid = bstrSignId;
			DataObj.bstroutfile = bstrOutFIle;
			DataObj.iflags = iFlags;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		HCA_PureSignUpdate : function(bData, iFlags, iHashFlag){
			var DataObj = new Object();
			DataObj.functionname = "HCA_PureSignUpdate";
			DataObj.bdata = bData;
			DataObj.iflags = iFlags;
			DataObj.ihashflag = iHashFlag;
			return ServiSignObj.Send(DataObj);
		},
		HCA_GetInitCertificate : function(){
			var DataObj = new Object();
			DataObj.functionname = "HCA_GetInitCertificate";
			return ServiSignObj.Send(DataObj);
		},
		ServiSign_GetErrorCode : function()
		{
			return ServiSign_GetErrorCode;
		}
	}
	window.addEventListener("unload", function () {
		ServiSignObj.ServiSignClose();
	});
	if(ServiSignObj.ServiSigninit()){
		return ServiSignInterface;
	}
	return undefined;
}

export default getCGHCAATLAdapterObj;
