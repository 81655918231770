//**********************************************************************************************************************************************************/''
//程式代號：ICCWebSocket.js
//程式名稱：讀卡機WebSocket程式定羲
//作業說明：讀卡機WebSocket程式定羲
// No.  YYYY/MM/DD  VER     AUTHOR      COMMENTS
//  1.  2022/10/17  1.0       KC         Create
//**********************************************************************************************************************************************************/''
import CGBrowserDetect from '../CG/CGBrowserDetect.js';

var ICCWebSocket = (function () {
    var NhiIccWsUri = CGBrowserDetect.browser == "Explorer" ? "wss://127.0.0.1:7777/echo" : "wss://localhost:7777/echo";
    try{
        var m_instance = new WebSocket(NhiIccWsUri);
    } catch(e) {
        console.log(e);
    }    
    return {
        Instance: function () {
            //readyState 屬性使用以下常數描述 WebSocket 的連線狀態。
            //常數 值 描述 
            //CONNECTING 0 連線尚未打開。 
            //OPEN 1 連線已打開，可以進行通訊。 
            //CLOSING 2 連線正在進行關閉程序。 
            //CLOSED 3 連線已關閉／連線不能打開。 
            if (m_instance == null) { m_instance = new WebSocket(NhiIccWsUri); OpenPortalPage(); throw "" }
            if (m_instance.readyState === 0) { m_instance = new WebSocket(NhiIccWsUri);  OpenPortalPage(); throw "" } //throw "websocket連線尚未打開";
            if (m_instance.readyState === 3) { m_instance = new WebSocket(NhiIccWsUri);  OpenPortalPage(); throw "" } //throw "websocket連線不能打開";
            return m_instance;
            //   ws.onopen = function (evt) { onOpen(evt) };
            //   ws.onclose = function (evt) { onClose(evt) };
            //   ws.onerror = function (evt) { onError(evt) };
            //   websocket.onmessage = function (evt) { onMessage(evt) };
            function OpenPortalPage() {
                var popupWinWidth = 722;
                var popupWinHeight = 300;
                var left =(screen.width - popupWinWidth) /2;
                var top = (screen.height - popupWinHeight) /4;
                var popUpObj = window.open("https://med.nhi.gov.tw/ca2/nhi/icc/page/portal.html",
                "ModalPopUp",
                "toolbar=no," +
                "scrollbars=no," +
                "location=no," +
                "statusbar=no," +
                "menubar=no," +
                "resizable=0,"+
                "width=" + popupWinWidth + ","+
                "height=" + popupWinHeight + ","+
                "left=" + left + ","+
                "top=" + top + ""
                );
                popUpObj.focus();
                //如果可以使用fancybox，就使用以下邏輯
                /*$.fancybox.open({
                    type: 'iframe',
                    closeBtn: true,
                    width: "722px",
                    height: "300px",
                    iframe: { scrolling: 'no', preload: true },
                    href: 'https://med.nhi.gov.tw/ca2/nhi/icc/page/portal.html',
                    afterClose: function () {
                        parent.location.reload(true);
                    }
                })*/
            }
        }
    };
})();

export default ICCWebSocket;