//**********************************************************************************************************************************************************/''
//程式代號：CA2Factory.js
//程式名稱：物件工廠類別
//作業說明：在此類別中，依據實際使狀況產出適用的物件，並達到抽換的目的。
// No.  YYYY/MM/DD  VER     AUTHOR      COMMENTS
//  1.  2022/10/17  1.0       KC         Create
//**********************************************************************************************************************************************************/''

//讀卡機js
import CA2SmartCardReader from './CA2SmartCardReader.js';

//卡片js
import CA2GovernmentCard from './CA2GovernmentCard.js';
import CA2HealthCard from './CA2HealthCard.js';
import CA2NatureHumanCard from './CA2NatureHumanCard.js';
import CA2OrgGroupCard from './CA2OrgGroupCard.js';
import CA2OrganizationCard from './CA2OrganizationCard.js';
import CA2PersonalCard from './CA2PersonalCard.js';
import CA2ShangCard from './CA2ShangCard.js';

var CA2Factory = {
    CreateGovernmentCard: function (UserInputPIN) {
        return new CA2GovernmentCard(UserInputPIN);
    },
    CreateHealthCard: function (UserInputPW) {
        return new CA2HealthCard(UserInputPW);
    },
    CreateNatureHumanCard: function (UserInputPIN, UserInputID) {
        return new CA2NatureHumanCard(UserInputPIN, UserInputID);
    },
    CreateOrganizationCard: function (UserInputPIN) {
        return new CA2OrganizationCard(UserInputPIN);
    },
    CreateOrgGroupCard: function (UserInputPIN) {
        return new CA2OrgGroupCard(UserInputPIN);
    },
    CreatePersonalCard: function (UserInputPIN) {
        return new CA2PersonalCard(UserInputPIN);
    },
    CreateShangCard: function (UserInputPIN) {
        return new CA2ShangCard(UserInputPIN);
    },
    CreateSmartCardReader: function () {
        return new CA2SmartCardReader();
    }
}

export default CA2Factory;
