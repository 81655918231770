//**********************************************************************************************************************************************************/''
//程式代號：CA2DataType.js
//程式名稱：代碼類別共用函式
//作業說明：
// No.  YYYY/MM/DD  VER     AUTHOR      COMMENTS
//  1.  2022/10/17  1.0       KC         Create
//**********************************************************************************************************************************************************/''

var CA2DataType = {
    NHIICC_A: 221, /* 健保IC卡認證異常代碼(A) */
    NHIICC_B: 222, /* 健保IC卡認證異常代碼(B) */
    NHIICC_C: 223, /* 健保IC卡認證異常代碼(C) */
    CGHCAATL_A: 230, /* 全景軟體HCA異常代碼(A) */
    CGGPKICryptATL_A: 231, /* 全景軟體GPKI異常代碼(A) */
    CGVAATL_B: 232, /* 全景軟體VAServer異常代碼(B) */
    NHICIE_A: 234, /*健保IC卡認證異常代碼(多元)(A)*/
    NHICA2_A: 235, /*憑證卡函式庫異常代碼(A)*/
    NHICA2_B: 236, /*憑證卡函式庫異常代碼(B)*/
    IDCHCSETUP_A: 237, /*憑證元件異常代碼(A)*/
    NHICardX_A: 238, /*健保讀卡機簽章異常代碼(A)*/
    NHICardX_B: 239, /*健保讀卡機驗章異常代碼(B)*/
    NHICS5_A: 240, /*雲端安全模組異常代碼(A)*/
    NHICS5_B: 241 /*雲端安全模組異常代碼(B)*/
}

export default CA2DataType;