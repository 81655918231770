import CGBrowserDetect from '../CG/CGBrowserDetect.js';

var getCGGPKICryptATLAdapterObj = function()
{
	var CGShareUrl = "";
	var ServiSignErrorCode = 61001;
	var LibName = "CGGPKICryptATLAdapter";								//元件名稱
	var minServiSignVersion = "1.0.16.0418";					//最低ServiSign版本需求
	var minLibVersion = "0.0.0.0";								//最低元件版本需求
	var LibDir = "CG";									//安裝資料夾
	// var JSVersion = "1.0.16.0624";
	function ServiSign_GetErrorCode(){
		return ServiSignErrorCode;
	}
	
	var ServiSignFlag_PathProtect    = true;					//路徑保護
	//DataObj.pfxpath_servisignflag = ServiSignFlag_PathProtect;
    //DataObj.pfxpath = pfxpath.replace(/\\/g, "*");

	//DataObj.topuidetect = true;							//設定UI置頂
	
	function CGServiSignConnectError(){
		ServiSignErrorCode = 61006;//ServiSign_RTN_CONNECTION_ERROR
	}
	
	function CGServiSignError(){
		ServiSignErrorCode = 61202;//ServiSign_JS_VERSION_FORMAT_ERROR  61202  61202
	}
	function LibError(){
		ServiSignErrorCode = 61201;//ServiSign_JS_LIB_VERSION_FORMAT_ERROR 61201
	}
	function CGServiSignVersionTooLow(){
		ServiSignErrorCode = 61203;//ServiSign_JS_SERVISIGN_VERSION_ERROR 61203
	}
	function LibVersionTooLow(){
		ServiSignErrorCode = 61204;//ServiSign_JS_LIB_VERSION_ERROR   61204
	}
	
	
	var Base64Encode = function (input) {
		return encodeURIComponent(window.btoa(unescape(encodeURIComponent(input))));
	}
	var Base64Decode = function (input) {
		if(input == undefined) return "";
		return decodeURIComponent(escape(window.atob(input)));
	}
	function FixArray(inputArray){
		var array = inputArray.split(";");
		array.splice(array.length - 1, array.length);
		array.toArray = function(){
			return this;
		}
		return array;
	}
	/*function KeepAlive(DataObj){
		var temp = "";
		DataObj.KeepAlive = true;
		DataObj.topuidetect = true;
		do{
			temp = ServiSignObj.Send(DataObj);
		}while(temp == "heartbeat");
		return temp;
	}*/
	function getServiSignObj()
	{
		var Url_Part1 = 'https://localhost:';
		var Get_osName = CGBrowserDetect.OS;// getOS();
		var portList;
		
		if(Get_osName=="Windows" || Get_osName=="Linux"){
			portList = new Array(56306, 56316, 56336);
		}else{
			portList = new Array(54306, 54316, 54336);
		}
		var SessionID ="";
		var WorkURL = "";
		
		var realServiSignVersion = "";
		var realLibVersion = "";
		
		var ServiSignObj = 
		{
			senData : function(url, DataObj){
				var resultObj;		
				var xhr = new XMLHttpRequest();
				xhr.open('POST',url,false);
				xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
				var onLoadHandler = function(){//event){
					try {
						resultObj = JSON.parse(this.responseText);
					}
					catch(err) {
						if(this.responseText != undefined){
							resultObj = new Object();
							resultObj.result = this.responseText.substring(11, this.responseText.length - 2);
						}
						else{
							resultObj = "";
						}
					}
				}
				xhr.onload = onLoadHandler;

				DataObj.comname = LibName;
				DataObj.libdir = LibDir;
				DataObj.minserverversion = minServiSignVersion;
				DataObj.sessionid = SessionID;
								
				var readyDataObj = "Parameter=" + Base64Encode(JSON.stringify(DataObj));
				
				try {
					xhr.send(readyDataObj);
				}
				catch(err) {
					return undefined;
				}
				return resultObj;
			},
			TryPort : function(){
				if(CGShareUrl == "fail") return false;
				else if(CGShareUrl != "") WorkURL = CGShareUrl;
				
				var resultObj;
				var DataObj = new Object();
				DataObj.functionname = "ServiSignEcho";
				DataObj.minlibversion = minLibVersion;
				DataObj.url = document.URL.substring(0, document.URL.indexOf("/", document.URL.indexOf("/") + 2));
				
				if(WorkURL == ""){
					for (var i = 0; i < portList.length; i++) {
						var url = Url_Part1 + portList[i];
						resultObj = this.senData(url, DataObj);
						if(resultObj != undefined){
							WorkURL = url;
							CGShareUrl = url;
							break;
						}
					}
				}
				else{
					resultObj = this.senData(WorkURL, DataObj);
				}
				if(resultObj == undefined){
					CGServiSignConnectError();
					return false;
				}
				ServiSignErrorCode = 0;
				if(WorkURL != ""){
					var VersionToVersion = function(input){
						var result = input;
						result = result.replace(" ","");
						result = result.replace(".",",");
						result = result.replace(".",",");
						result = result.replace(".",",");
						return result.split(",");
					};
					if(resultObj.ServerVersion == undefined){
						ServiSignErrorCode = Base64Decode(resultObj.result);
						return false;
					}
					
					realServiSignVersion = Base64Decode(resultObj.ServerVersion);
					realLibVersion = Base64Decode(resultObj.LibVersion);
					
					var realServerVersionArray = VersionToVersion(realServiSignVersion);
					var realLibVersionArray = VersionToVersion(realLibVersion);
					
					var minServiSignVersionArray = VersionToVersion(minServiSignVersion);
					var minLibVersionArray = VersionToVersion(minLibVersion);
					
					if(realServerVersionArray.length != minServiSignVersionArray.length){
						CGServiSignError();
						return false;
					}
					if(realLibVersionArray.length != minLibVersionArray.length){
						LibError();
						return false;
					}
						
					var VerifyVersion = true;
					for( i = 0 ; i < minServiSignVersionArray.length ; i++){
						if(parseInt(realServerVersionArray[i]) < parseInt(minServiSignVersionArray[i])){
							CGServiSignVersionTooLow();
							VerifyVersion = false;
							break;
						}
						else if(parseInt(realServerVersionArray[i]) > parseInt(minServiSignVersionArray[i])){
							break;
						}
					}
					for( i = 0 ; i < minLibVersionArray.length ; i++){
						if(parseInt(realLibVersionArray[i]) < parseInt(minLibVersionArray[i])){
							LibVersionTooLow();
							VerifyVersion = false;
							break;
						}
						else if(parseInt(realLibVersionArray[i]) > parseInt(minLibVersionArray[i])){
							break;
						}
					}
					if(SessionID == ""){
						SessionID = Base64Decode(resultObj.SessionID);
					}
					return VerifyVersion;
				}
				else{
					CGShareUrl = "fail";
					ServiSignErrorCode = 61001;
					return false;
				}
			},
			Send : function(DataObj){
				DataObj.comname = LibName;
				var resultObj = this.senData(WorkURL, DataObj);
				return Base64Decode(resultObj.result);
			},
			getCGServiSignVersion : function(){
				return realServiSignVersion;
			},
			getLibVersion : function(){
				return realLibVersion;
			},
			ServiSigninit : function()
			{
				var x = new XMLHttpRequest();
				if( window.FormData === undefined || x == undefined){
					alert("This Browser Does NOT support HTML5");
					return false;
				}
				return this.TryPort();
			},
			ServiSignClose : function()
			{
				var DataObj = new Object();
				DataObj.functionname = "ServiSignClose";
				DataObj.minlibversion = minLibVersion;
				return this.Send(DataObj);
			}
		};
		return ServiSignObj;
	}
	var ServiSignObj = getServiSignObj();
	var ServiSignInterface = 
	{
		GetServiSignVersion : function()
		{
			return ServiSignObj.getCGServiSignVersion();
		},
		GetLibVersion : function()
		{
			return ServiSignObj.getLibVersion();
		},
		get_lastError : function(){
			var DataObj = new Object();
			DataObj.functionname = "get_lastError";
			return parseInt(ServiSignObj.Send(DataObj));
		},
		GPKI_EnumCerts : function(iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_EnumCerts";
			DataObj.iflags = iFlags;
			return FixArray(ServiSignObj.Send(DataObj));
		},
		CAPICertGetSerialNumber : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "CAPICertGetSerialNumber";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		CAPICertGetIssuer : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "CAPICertGetIssuer";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		CAPICertGetSubject : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "CAPICertGetSubject";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_GetTailOfCitizenID : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_GetTailOfCitizenID";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_GetCertType : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_GetCertType";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		GPKI_GetOrganizationID : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_GetOrganizationID";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_GetCardHolderRank : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_GetCardHolderRank";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_GetCertTypeOID : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_GetCertTypeOID";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_GetEntityOID : function(cert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_GetEntityOID";
			DataObj.cert = cert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		CAPICertGetPublicKey : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "CAPICertGetPublicKey";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		CAPICertGetNotBefore : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "CAPICertGetNotBefore";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		CAPICertGetNotAfter : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "CAPICertGetNotAfter";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		CAPICertGetVersion : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "CAPICertGetVersion";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_EncryptByCert : function(strCert, strData, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_EncryptByCert";
			DataObj.strcert = strCert;
			DataObj.strdata = strData;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_Decrypt : function(strCipher, iFlags, iKeyUsage){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_Decrypt";
			DataObj.strcipher = strCipher;
			DataObj.iflags = iFlags;
			DataObj.ikeyusage = iKeyUsage;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_Decrypt2File : function(strCipher, strOutFilePath, iFlags, iKeyUsage){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_Decrypt2File";
			DataObj.strcipher = strCipher;
			DataObj.stroutfilepath = strOutFilePath.replace(/\\/g, "*");
			DataObj.stroutfilepath_servisignflag = ServiSignFlag_PathProtect;
			DataObj.iflags = iFlags;
			DataObj.ikeyusage = iKeyUsage;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		GPKI_XMLSignTemplate : function(bstrPIN, bstrXML, bstrSignId, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_XMLSignTemplate";
			DataObj.bstrpin = bstrPIN;
			DataObj.bstrxml = bstrXML;
			DataObj.bstrsignid = bstrSignId;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_SetReaderSlot : function(iFlags, slot){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_SetReaderSlot";
			DataObj.iflags = iFlags;
			DataObj.slot = slot;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		GPKI_EnumCertsToString : function(iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_EnumCertsToString";
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_Base64Encode : function(srcData, codePage){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_Base64Encode";
			DataObj.srcdata = srcData;
			DataObj.codepage = codePage;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_Base64Decode : function(base64String, outCodePage){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_Base64Decode";
			DataObj.base64string = base64String;
			DataObj.outcodepage = outCodePage;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_DecryptEx : function(sOptPIN, strCipher, iFlags, iKeyUsage){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_DecryptEx";
			DataObj.soptpin = sOptPIN;
			DataObj.strcipher = strCipher;
			DataObj.iflags = iFlags;
			DataObj.ikeyusage = iKeyUsage;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_SignDataInit : function(sOptPIN, lFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_SignDataInit";
			DataObj.soptpin = sOptPIN;
			DataObj.lflags = lFlags;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		GPKI_SignDataFinal : function(lFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_SignDataFinal";
			DataObj.lflags = lFlags;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		GPKI_XMLSignTemplateFile : function(bstrPIN, bstrXMLFilePath, bstrSignId, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_XMLSignTemplateFile";
			DataObj.bstrpin = bstrPIN;
			DataObj.bstrxmlfilepath = bstrXMLFilePath.replace(/\\/g, "*");
			DataObj.bstrxmlfilepath_servisignflag = ServiSignFlag_PathProtect;
			DataObj.bstrsignid = bstrSignId;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_XMLCalcTemplateDigest : function(bstrXML, bstrSignId){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_XMLCalcTemplateDigest";
			DataObj.bstrxml = bstrXML;
			DataObj.bstrsignid = bstrSignId;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_XMLCalcTemplateFileDigest : function(bstrXMLFilePath, bstrSignId){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_XMLCalcTemplateFileDigest";
			DataObj.bstrxmlfilepath = bstrXMLFilePath.replace(/\\/g, "*");
			DataObj.bstrxmlfilepath_servisignflag = ServiSignFlag_PathProtect;
			DataObj.bstrsignid = bstrSignId;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_XMLSignTemplateDigest : function(bstrPIN, bstrXML, bstrSignId, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_XMLSignTemplateDigest";
			DataObj.bstrpin = bstrPIN;
			DataObj.bstrxml = bstrXML;
			DataObj.bstrsignid = bstrSignId;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_ReadFile : function(strFilepath, flags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_ReadFile";
			DataObj.strfilepath = strFilepath.replace(/\\/g, "*");
			DataObj.strfilepath_servisignflag = ServiSignFlag_PathProtect;
			DataObj.flags = flags;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_XMLSignTemplateFileUpdate : function(bstrXMLFilePath, bstrSignId, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_XMLSignTemplateFileUpdate";
			DataObj.bstrxmlfilepath = bstrXMLFilePath.replace(/\\/g, "*");
			DataObj.bstrxmlfilepath_servisignflag = ServiSignFlag_PathProtect;
			DataObj.bstrsignid = bstrSignId;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_SignInit : function(sOptPIN, lFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_SignInit";
			DataObj.soptpin = sOptPIN;
			DataObj.lflags = lFlags;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		GPKI_SignFinal : function(lFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_SignFinal";
			DataObj.lflags = lFlags;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		GPKI_XMLSignTemplateDigestUpdate : function(bstrXML, bstrSignId, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_XMLSignTemplateDigestUpdate";
			DataObj.bstrxml = bstrXML;
			DataObj.bstrsignid = bstrSignId;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_SetPKCS11Driver : function(bPath){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_SetPKCS11Driver";
			DataObj.bpath = bPath.replace(/\\/g, "*");
			DataObj.bpath_servisignflag = ServiSignFlag_PathProtect;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		SetXMLReferenceDirs : function(bstrMultiRefDir){
			var DataObj = new Object();
			DataObj.functionname = "SetXMLReferenceDirs";
			DataObj.bstrmultirefdir = bstrMultiRefDir;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_GetSerialNumber : function(){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_GetSerialNumber";
			return ServiSignObj.Send(DataObj);
		},
		GPKI_RSADecrypt : function(strCipher, strPinCode, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_RSADecrypt";
			DataObj.strcipher = strCipher;
			DataObj.strpincode = strPinCode;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_RSAEncryptByCert : function(strCert, strData, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_RSAEncryptByCert";
			DataObj.strcert = strCert;
			DataObj.strdata = strData;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_XMLSignTemplateUpdate : function(bstrXML, bstrSignId, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_XMLSignTemplateUpdate";
			DataObj.bstrxml = bstrXML;
			DataObj.bstrsignid = bstrSignId;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_XMLSignTemplateFile2File : function(bstrPIN, bstrInXMLFilePath, bstrOutXMLFilePath, bstrSignId, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_XMLSignTemplateFile2File";
			DataObj.bstrpin = bstrPIN;
			DataObj.bstrinxmlfilepath = bstrInXMLFilePath.replace(/\\/g, "*");
			DataObj.bstrinxmlfilepath_servisignflag = ServiSignFlag_PathProtect;
			DataObj.bstroutxmlfilepath = bstrOutXMLFilePath.replace(/\\/g, "*");
			DataObj.bstroutxmlfilepath_servisignflag = ServiSignFlag_PathProtect;
			DataObj.bstrsignid = bstrSignId;
			DataObj.iflags = iFlags;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		GPKI_XMLSignTemplateDigest2File : function(bstrPIN, bstrXML, bstrOutXMLFilePath, bstrSignId, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_XMLSignTemplateDigest2File";
			DataObj.bstrpin = bstrPIN;
			DataObj.bstrxml = bstrXML;
			DataObj.bstroutxmlfilepath = bstrOutXMLFilePath.replace(/\\/g, "*");
			DataObj.bstroutxmlfilepath_servisignflag = ServiSignFlag_PathProtect;
			DataObj.bstrsignid = bstrSignId;
			DataObj.iflags = iFlags;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		GPKI_GetAttributeCert : function(strReader, strPIN, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_GetAttributeCert";
			DataObj.strreader = strReader;
			DataObj.strpin = strPIN;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		SetCodePage : function(codePage){
			var DataObj = new Object();
			DataObj.functionname = "SetCodePage";
			DataObj.codepage = codePage;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_GetSlotCount : function(){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_GetSlotCount";
			return parseInt(ServiSignObj.Send(DataObj));
		},
		GPKI_VerifyPIN : function(bPIN, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_VerifyPIN";
			DataObj.bpin = bPIN;
			DataObj.iflags = iFlags;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		GPKI_GetErrorPINCount : function(){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_GetErrorPINCount";
			return parseInt(ServiSignObj.Send(DataObj));
		},
		GetVersion : function(flag){
			var DataObj = new Object();
			DataObj.functionname = "GetVersion";
			DataObj.flag = flag;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_SignData : function(sOptPIN, sData, signcodepage, iFlags, iHashFlag){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_SignData";
			DataObj.soptpin = sOptPIN;
			DataObj.sdata = sData;
			DataObj.signcodepage = signcodepage;
			DataObj.iflags = iFlags;
			DataObj.ihashflag = iHashFlag;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_SignDataUpdate : function(sData, signcodepage, iFlags, iHashFlag){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_SignDataUpdate";
			DataObj.sdata = sData;
			DataObj.signcodepage = signcodepage;
			DataObj.iflags = iFlags;
			DataObj.ihashflag = iHashFlag;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_PureSign : function(sOptPIN, sData, signcodepage, iFlags, iHashFlag){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_PureSign";
			DataObj.soptpin = sOptPIN;
			DataObj.sdata = sData;
			DataObj.signcodepage = signcodepage;
			DataObj.iflags = iFlags;
			DataObj.ihashflag = iHashFlag;
			return ServiSignObj.Send(DataObj);
		},
		CryptCertGetSignatureAlgorithm : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "CryptCertGetSignatureAlgorithm";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		CryptSignatureGetAlgorithm : function(strSignature, iFlags, index){
			var DataObj = new Object();
			DataObj.functionname = "CryptSignatureGetAlgorithm";
			DataObj.strsignature = strSignature;
			DataObj.iflags = iFlags;
			DataObj.index = index;
			return ServiSignObj.Send(DataObj);
		},
		GPKI_SignInitLogin : function(sOptPIN, lFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_SignInitLogin";
			DataObj.soptpin = sOptPIN;
			DataObj.lflags = lFlags;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		GPKI_DetectCard : function(iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_DetectCard";
			DataObj.iflags = iFlags;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		GPKI_GetCachedCert : function(){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_GetCachedCert";
			return ServiSignObj.Send(DataObj);
		},
		GPKI_CertGetSubjectDirectoryAttrs : function(strCert, strOID, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "GPKI_CertGetSubjectDirectoryAttrs";
			DataObj.strcert = strCert;
			DataObj.stroid = strOID;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		CryptCertGetExtension : function(strCert, iFlags){
			var DataObj = new Object();
			DataObj.functionname = "CryptCertGetExtension";
			DataObj.strcert = strCert;
			DataObj.iflags = iFlags;
			return ServiSignObj.Send(DataObj);
		},
		SetDialogString : function(dlgID, IDS, value){
			var DataObj = new Object();
			DataObj.functionname = "SetDialogString";
			DataObj.dlgid = dlgID;
			DataObj.ids = IDS;
			DataObj.value = value;
			return parseInt(ServiSignObj.Send(DataObj));
		},
		ServiSign_GetErrorCode : function()
		{
			return ServiSign_GetErrorCode;
		}
	}
	window.addEventListener("unload", function () {
		ServiSignObj.ServiSignClose();
	});
	if(ServiSignObj.ServiSigninit()){
		return ServiSignInterface;
	}
	return undefined;
}

export default getCGGPKICryptATLAdapterObj;