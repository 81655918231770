//**********************************************************************************************************************************************************/''
//程式代號：ICCpkiSingleton.js
//程式名稱：ICC軟體共用程式獨體類別
//作業說明：在此類別中的物件，可確保在Javascript程式的任何地方，取得同一個物件實體。
// No.  YYYY/MM/DD  VER     AUTHOR      COMMENTS
//  1.  2022/10/17  1.0       KC         Create
//**********************************************************************************************************************************************************/''
import CGBrowserDetect from '../CG/CGBrowserDetect.js';
import ICCWebSocket from '../ICC/ICCWebSocket.js';
import CA2ServerAction from '../CA2/CA2ServerAction.js';
import CA2DataType from '../CA2/CA2DataType.js';

var ICCpkiSingleton = (function () {
    var m_instance;
    //建立API實體
    function Instance() {
        return new ICCpki();
    }
    return {
        Instance: function () {
            if (!m_instance) { m_instance = Instance(); } return m_instance;
        }
    };
})();

function ICCpki() {
    //初始化檢查
    var browserName = CGBrowserDetect.browser;
    var osName2 = CGBrowserDetect.OS; // getOS(); //Windows, Mac, Linux
    //var DownloadFileMsg = CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1038);
    var VersionMsg = CA2ServerAction.CodeToMsg(CA2DataType.NHICA2_A, 1049);

    if (osName2 == "Windows" || osName2 == "Linux" || osName2 == "Mac") {

        if (browserName == "Explorer" || browserName == "Firefox" || browserName == "Chrome" || browserName == "Edge" || browserName == "Opera" || browserName == "Safari") {   //其他瀏覽器使用mNHIICC
            try {
                if (ICCWebSocket.Instance().readyState != 1) throw "健保卡片驗證元件(mNHIICC)連線尚未打開";
            } catch (e) {

                throw ""; //中斷處理程序，但不顯示訊息。
            }
        } else {    //不認識的瀏覽器
            throw "不支援的瀏覽器: " + browserName + "；請改用IE, Chrome, FireFox, Safari, Edge, Opera等瀏覽器執行" + "\nOS:" + osName2 + ", Browser: " + browserName;
        }
    } else {    //不認識的作業系統不支援
        throw "請使用Windows, Linux, Mac作業系統";
    }
    
    this.IccWebSocket = function () {
        return ICCWebSocket.Instance();
    };


    //檢查版本(非同步)
    this.CheckVersion = function (v) {
        var EName = JSON.parse(VersionMsg.En);
        var ver;
        switch (osName2) {
            case "Windows":
                ver = EName.Windows.Version;
                break;
            case "Mac":
                ver = EName.Mac.Version;
                break;
            case "Linux":
                ver = EName.Linux.Version;
                break;
            default:
                ver = "*";
        }
        var NeedUpdateInstaller = function () {
            var vers = ver.split('|');
            for (var i = 0; i < vers.length; i++) {
                if ("*" == vers[i].replace(/\./g, "")) return false;
                if (v == vers[i].replace(/\./g, "")) return false;
            } return true;
        }
        if (NeedUpdateInstaller()) {
            //alert(VersionMsg.Cn + "\n[" + VersionMsg.Ty + VersionMsg.Co + "]");
            OpenPortalPage(); throw ""; /*中斷處理程序，但不顯示訊息*/
        }
        //版號檢核通過
    }
    
    function OpenPortalPage() {
        var popupWinWidth = 722;
        var popupWinHeight = 300;
        var left =(screen.width - popupWinWidth) /2;
        var top = (screen.height - popupWinHeight) /4;
        var popUpObj = window.open("https://med.nhi.gov.tw/ca2/nhi/icc/page/portal.html",
        "ModalPopUp",
        "toolbar=no," +
        "scrollbars=no," +
        "location=no," +
        "statusbar=no," +
        "menubar=no," +
        "resizable=0,"+
        "width=" + popupWinWidth + ","+
        "height=" + popupWinHeight + ","+
        "left=" + left + ","+
        "top=" + top + ""
        );
        popUpObj.focus();
        //如果可以使用fancybox，就使用以下邏輯
        /*$.fancybox.open({
            type: 'iframe',
            closeBtn: true,
            width: "722px",
            height: "300px",
            iframe: { scrolling: 'no', preload: true },
            href: 'https://med.nhi.gov.tw/ca2/nhi/icc/page/portal.html',
            afterClose: function () {
                parent.location.reload(true);
            }
        })*/
    }



}

export default ICCpkiSingleton;