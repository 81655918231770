//**********************************************************************************************************************************************************/''
//程式代號：CGCertType.js
//程式名稱：全景憑證類別共用函式
//作業說明：全景憑證類別共用函式
// No.  YYYY/MM/DD  VER     AUTHOR      COMMENTS
//  1.  2022/10/17  1.0       KC         Create
//**********************************************************************************************************************************************************/''

var CGCertType = {
    CG_CERT_TYPE_1: 101, /* 國民 */
    CG_CERT_TYPE_2: 102, /* 政府機關 */
    CG_CERT_TYPE_3: 103, /* 政府單位 */
    CG_CERT_TYPE_4: 104, /* 學校 */
    CG_CERT_TYPE_5: 105, /* 公司 */
    CG_CERT_TYPE_6: 106, /* 社團法人 */
    CG_CERT_TYPE_7: 107, /* 財團法人 */
    CG_CERT_TYPE_8: 108, /* 商號 */
    CG_CERT_TYPE_9: 109, /* 分公司 */
    CG_CERT_TYPE_10: 110, /* ServerAP */
    CG_CERT_TYPE_11: 111, /* 醫事人員, HCA */
    CG_CERT_TYPE_12: 112, /* 醫事機構, HCA */
    CG_CERT_TYPE_13: 113, /* 自由職業事務所, XCA */
    CG_CERT_TYPE_14: 114, /* 其他組織或團體, XCA */
    CG_CERT_TYPE_15: 115, /* 外來人口 */
    CG_CERT_TYPE_16: 116, /* 有限合夥 */
    CG_CERT_TYPE_17: 117, /* 有限合夥分支機構 */
    CG_CERT_TYPE_18: 118 /* 行政法人 */
}

export default CGCertType;