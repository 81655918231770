import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Resource from 'vue-resource'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import '@/assets/css/all.css'



Vue.use(Resource)
Vue.config.productionTip = false
Vue.http.options.root = process.env.VUE_APP_API

Vue.http.interceptors.push((request) => {
  request.headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('token'))
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
