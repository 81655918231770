//**********************************************************************************************************************************************************/''
//程式代號：CA2Message.js
//程式名稱：訊息類別
//作業說明：在此類別中，處理前端訊息的呈現。
// No.  YYYY/MM/DD  VER     AUTHOR      COMMENTS
//  1.  2022/10/17  1.0       KC         Create
//**********************************************************************************************************************************************************/''
var CA2Message = {
    Show: function (e) {
        var msg = "";
        switch (Object.prototype.toString.call(e)) {
            case "[object Object]":
                msg = e.Cn + "[" + e.Ty + e.Co + "]";
                console.log((e.ID != null ? "[ID:" + e.ID + "]" : "") + (e.OID != null ? "[OID:" + e.OID + "]" : ""));
                alert(msg);
                break;
            case "[object String]":
                if (e != "") alert(e);
                break;
            default:
                alert(e.message);
        }
    }
}

export default CA2Message;